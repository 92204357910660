import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Conference, DX_USD_CURRENCY_FORMAT } from 'ag-common-lib/public-api';
import { Registrant } from 'ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { ConferenceRegistrantsService } from 'ag-common-svc/public-api';

@Component({
  selector: 'app-registration-reports',
  templateUrl: './registration-reports.component.html',
  styleUrls: ['./registration-reports.component.scss'],
})
export class RegistrationReportsComponent implements OnInit {
  reportYears: number[] = [];

  event: Conference;
  fromYear: number;
  toYear: number;
  reportType: string;
  columns = [];

  conferences: Promise<Conference[]>;
  registrants: Promise<Registrant[]>;

  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  constructor(
    public conferenceService: ConferenceService,
    public toastrService: ToastrService,
    public registrantService: ConferenceRegistrantsService,
  ) {}

  ngOnInit(): void {
    this.reportYears = [];
    this.reportYears.push(new Date().getFullYear());
    this.reportYears.push(new Date().getFullYear() - 1);
    this.reportYears.push(new Date().getFullYear() - 2);

    this.conferences = this.conferenceService.getAll();
  }

  generateReport() {
    this.columns = [];
    this.columns.push({ dataField: 'agent.p_agent_name', caption: 'Agent Name' });

    if (this.fromYear > this.toYear) {
      this.toastrService.error("'From year' must be prior to or equal to 'To year'");
    } else {
      if (this.reportType == 'Registration Report with Financial Data') {
        let yearcount = this.toYear - this.fromYear;
        let years = [];

        for (let i = 0; i <= yearcount; i++) {
          let y = this.toYear - i;
          years.push(y);

          this.columns.push({
            dataField: y + '-premium',
            caption: y + ' Weighted Premium',
            dataType: 'number',
            format: DX_USD_CURRENCY_FORMAT,
          });
        }
      } else {
        this.event.registrantFields.forEach(field => {
          this.columns.push({ dataField: field.name, caption: field.label });
        });

        // todo fix
        // this.registrants = firstValueFrom(this.registrantService.getRegistrantsByConferenceId(this.con.dbId, 'last_name'));
      }
    }
  }
}
