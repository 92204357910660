<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    #formRef
    *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
    [formData]="hotelReservation"
    labelMode="floating"
    [validationGroup]="validationGroup"
    [screenByWidth]="formSize$ | async | formScreenByWidth"
    [readOnly]="!canWrite"
    (onInitialized)="onFormInitialized($event)"
  >
    <dxi-item
      *var="hotelTaskStatus$ | async as hotelTaskStatus"
      [visible]="canWrite"
    >
      <div *dxTemplate class="d-flex flex-row align-items-center gap-2">
        <div class="d-flex flex-row align-items-center gap-2">
          Owner:
          <ag-shr-assign-owner-viewer
            [owner]="{ assignedTo: hotelTaskStatus?.assignedTo ?? null, assignedToNote: hotelTaskStatus?.note ?? '' }"
            [assignOwnerList]="assignOwnerList$ | async"
            [onOwnerChange]="onOwnerChange"
          ></ag-shr-assign-owner-viewer>
        </div>
        <div class="d-flex flex-row align-items-center gap-2">
          Status:

          <dx-select-box
            [value]="hotelTaskStatus?.status"
            (onValueChanged)="onTaskStatusChanged(hotelTaskStatus?.status, $event)"
            [dataSource]="taskStatus"
            valueExpr="value"
            displayExpr="description"
          ></dx-select-box>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      itemType="group"
      [visible]="
        hotelReservation[HotelReservationKeys.additionalRoomRequested] ||
        !!hotelReservation[HotelReservationKeys.additionalInfo]
      "
    >
      <dxi-item [visible]="isRequestedAdditionalRoom$ | async">
        <div *dxTemplate class="attendee-details-modal__request-container">
          <b>Additional Room Requested.</b>

          <dx-button
            type="success"
            icon="agi-thumbs-up"
            class="inline-editor-control ms-2"
            hint="Approve Request"
            [disabled]="!canWrite"
            [ngClass]="additionalRoomRequestOutcome$ | async | requestOutcomeIconClass : RequestOutcomeState.approved"
            (onClick)="handleAdditionalRoomRequest(RequestOutcomeState.approved)"
          ></dx-button>

          <dx-button
            type="danger"
            stylingMode="outlined"
            icon="agi-thumbs-down"
            class="inline-editor-control ms-2"
            hint="Reject Request"
            [disabled]="!canWrite"
            [ngClass]="additionalRoomRequestOutcome$ | async | requestOutcomeIconClass : RequestOutcomeState.rejected"
            (onClick)="handleAdditionalRoomRequest(RequestOutcomeState.rejected)"
          ></dx-button>

          <i *ngIf="additionalRoomRequestOutcome$ | async">{{
            additionalRoomRequestOutcome$ | async | requestOutcomeResult
          }}</i>

          <ag-shr-modal-window
            #approveAdditionalRoomRequestModalRef
            [width]="450"
            [height]="'auto'"
            title="Approve Request"
            [actionTitle]="'Confirm'"
            [useScrollView]="false"
            [inProgress]="requestInProgress$ | async"
            (onSaveClick)="handleAdditionalRoomRequestOutcome(RequestOutcomeState.approved)"
          >
            <ng-container *ngIf="approveAdditionalRoomRequestModalRef?.popupComponent?.visible ?? false">
              <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
            </ng-container>
          </ag-shr-modal-window>

          <ag-shr-modal-window
            #rejectAdditionalRoomRequestModalRef
            [width]="450"
            [height]="'auto'"
            title="Reject Request"
            [actionTitle]="'Confirm'"
            [useScrollView]="false"
            [inProgress]="requestInProgress$ | async"
            (onSaveClick)="handleAdditionalRoomRequestOutcome(RequestOutcomeState.rejected)"
          >
            <ng-container *ngIf="rejectAdditionalRoomRequestModalRef?.popupComponent?.visible ?? false">
              <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
            </ng-container>
          </ag-shr-modal-window>
        </div>
      </dxi-item>

      <dxi-item [visible]="!!hotelReservation[HotelReservationKeys.additionalInfo]">
        <div *dxTemplate>
          <b>Additional Info: </b>
          <span>
            {{ hotelReservation[HotelReservationKeys.additionalInfo] }}
          </span>
        </div>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="colCountByScreen" caption="Check-in / Check-Out Dates">
      <dxi-item>
        <ng-container *dxTemplate>
          <dx-date-range-box
            class="attendee-hotel-reservation__date-range-box"
            [startDate]="hotelReservation[HotelReservationKeys.checkInDate]"
            [endDate]="hotelReservation[HotelReservationKeys.checkOutDate]"
            (startDateChange)="hotelReservation[HotelReservationKeys.checkInDate] = $any($event)"
            (endDateChange)="hotelReservation[HotelReservationKeys.checkOutDate] = $any($event)"
            startDateLabel="Start"
            endDateLabel="End"
            labelMode="floating"
            [multiView]="conferencePossibleStayPeriod$ | async | isMultiView"
            [displayFormat]="dateFormat"
            [deferRendering]="false"
            [showClearButton]="true"
            [openOnFieldClick]="true"
            applyValueMode="useButtons"
            [dropDownOptions]="{ wrapperAttr: { class: 'attendee-hotel-reservation__date-range-box-drop-down' } }"
            [useMaskBehavior]="true"
            [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
            [disabled]="!canWrite"
          >
            <span
              *dxTemplate="let cell of 'cell'"
              class="attendee-hotel-reservation__cell"
              [ngClass]="cell | mainDateClass : (conferencePossibleStayPeriod$ | async)"
            >
              {{ cell.text }}
            </span>
          </dx-date-range-box>

          <div
            *ngIf="isRequestedDifferentHotelCheckInCheckOutDates$ | async"
            class="attendee-details-modal__request-container mt-2"
          >
            <b> Requested Differ Check-In Check-Out Dates: </b>

            <span>
              {{ hotelReservation?.[HotelReservationKeys.requestedCheckInDate] | datesRange : hotelReservation?.[HotelReservationKeys.requestedCheckOutDate]}}
            </span>

            <dx-button
              type="success"
              icon="agi-thumbs-up"
              class="inline-editor-control ms-2"
              hint="Approve Request"
              [disabled]="!canWrite"
              [ngClass]="
                requestDifferBookingDatesOutcome$ | async | requestOutcomeIconClass : RequestOutcomeState.approved
              "
              (onClick)="handleDifferBookingDates(RequestOutcomeState.approved)"
            ></dx-button>

            <dx-button
              type="danger"
              stylingMode="outlined"
              icon="agi-thumbs-down"
              class="inline-editor-control ms-2"
              hint="Reject Request"
              [disabled]="!canWrite"
              [ngClass]="
                requestDifferBookingDatesOutcome$ | async | requestOutcomeIconClass : RequestOutcomeState.rejected
              "
              (onClick)="handleDifferBookingDates(RequestOutcomeState.rejected)"
            ></dx-button>

            <i *ngIf="requestDifferBookingDatesOutcome$ | async">{{
              requestDifferBookingDatesOutcome$ | async | requestOutcomeResult
            }}</i>

            <ag-shr-modal-window
              #approveDifferBookingDatesRequestModalRef
              [width]="450"
              [height]="'auto'"
              title="Approve Request"
              [actionTitle]="'Confirm'"
              [useScrollView]="false"
              [inProgress]="requestInProgress$ | async"
              (onSaveClick)="handleDifferBookingDatesRequestOutcome(RequestOutcomeState.approved)"
            >
              <ng-container *ngIf="approveDifferBookingDatesRequestModalRef?.popupComponent?.visible ?? false">
                <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
              </ng-container>
            </ag-shr-modal-window>

            <ag-shr-modal-window
              #rejectDifferBookingDatesRequestModalRef
              [width]="450"
              [height]="'auto'"
              title="Reject Request"
              [actionTitle]="'Confirm'"
              [useScrollView]="false"
              [inProgress]="requestInProgress$ | async"
              (onSaveClick)="handleDifferBookingDatesRequestOutcome(RequestOutcomeState.rejected)"
            >
              <ng-container *ngIf="rejectDifferBookingDatesRequestModalRef?.popupComponent?.visible ?? false">
                <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
              </ng-container>
            </ag-shr-modal-window>
          </div>
        </ng-container>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="colCountByScreen" caption="Preferences">
      <dxi-item
        [dataField]="HotelReservationKeys.bedPreference"
        editorType="dxSelectBox"
        cssClass="hotel-reservation-form__select"
        [label]="{ visible: false }"
        [editorOptions]="{
          items: bedPreferenceLookup,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description
        }"
      >
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group">
      <dxi-item>
        <b *dxTemplate>Require Aids or Services:</b>
      </dxi-item>

      <dxi-item
        [dataField]="HotelReservationKeys.aidsRequired"
        [label]="{ visible: false }"
        [editorType]="'dxRadioGroup'"
        [cssClass]="'flex-column'"
        [editorOptions]="{
          items: aidsLookup,
          displayExpr: 'description',
          valueExpr: 'value'
        }"
      >
      </dxi-item>
    </dxi-item>
  </dx-form>
</dx-scroll-view>

<ag-crm-attendee-note-modal></ag-crm-attendee-note-modal>
