import {
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplatesDetailsMap,
  EmailTemplatesModels,
} from '@ag-common-lib/public-api';

export function filterTemplatesByDataModel(
  model: EmailTemplatesModels | [],
  emailTemplates: EmailTemplateConfiguration[],
): EmailTemplateConfiguration[] {
  const modelsSet = new Set(Array.isArray(model) ? model : [model]);
  return emailTemplates?.filter(emailTemplateConfiguration => {
    const templateName = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.templateName];
    const templateDetails = EmailTemplatesDetailsMap.get(templateName);

    return modelsSet.has(templateDetails.model);
  });
}
