<ng-container *var="flightDataSource$ | async as flightDataSource">
  <dx-scroll-view height="100%" [direction]="'vertical'" [showScrollbar]="'always'">
    <ng-container *var="flyTaskStatus$ | async as flyTaskStatus">
      <div
        class="d-flex gap-2 mb-2 mt-2"
        *ngIf="(Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async)"
      >
        <div class="d-flex flex-row align-items-center gap-2">
          Owner:
          <ag-shr-assign-owner-viewer
            [owner]="{
              assignedTo: flyTaskStatus?.assignedTo ?? null,
              assignedToNote: flyTaskStatus?.assignedPersonNote ?? '',
            }"
            [assignOwnerList]="assignOwnerList$ | async"
            [onOwnerChange]="onOwnerChange"
          ></ag-shr-assign-owner-viewer>
        </div>

        <div class="d-flex flex-row align-items-center gap-2">
          Status:

          <dx-select-box
            [value]="flyTaskStatus?.status"
            (onValueChanged)="onTaskStatusChanged(flyTaskStatus?.status, $event)"
            [dataSource]="taskStatus"
            valueExpr="value"
            displayExpr="description"
          ></dx-select-box>
        </div>
      </div>

      <dx-accordion
        [collapsible]="true"
        [multiple]="true"
        [animationDuration]="300"
        [elementAttr]="{ class: 'ag-crm-attendee-flight__accordion' + disableArrowCssClass }"
        [deferRendering]="false"
        [items]="attendeesTravelGroup"
        [selectedItems]="attendeesTravelGroup"
        [noDataText]="'No Data'"
        width="auto"
        height="auto"
      >
        <div *dxTemplate="let travel of 'title'">
          <p class="d-flex gap-2 align-items-center mb-0">
            {{ travel.title }}
            <i
              class="dx-icon dx-icon-warning attendee-details-header__warning-icon"
              *ngIf="travel.id === 'bookedByOwn'"
            ></i>
          </p>
        </div>

        <div *dxTemplate="let travel of 'item'">
          <ng-container [ngSwitch]="travel.id">
            <ng-container *ngSwitchCase="TravelMode.flying">
              <ag-crm-attendee-flying-grid
                [flightDataSource]="flightDataSource?.flights"
                [flightGroupsMap]="flightDataSource?.flightGroupsMap"
                [flyTaskStatus]="flyTaskStatus"
              ></ag-crm-attendee-flying-grid>
            </ng-container>

            <ng-container *ngSwitchCase="'bookedByOwn'">
              <ag-crm-attendee-flying-grid
                [flightDataSource]="flightDataSource?.flightsBookedByOwn"
                [flightGroupsMap]="flightDataSource?.flightGroupsMap"
                [flyTaskStatus]="flyTaskStatus"
              ></ag-crm-attendee-flying-grid>
            </ng-container>

            <ng-container *ngSwitchCase="TravelMode.driving">
              <dx-data-grid
                class="ag-crm-attendee-flight__grid"
                [dataSource]="flightDataSource?.driving"
                [showColumnLines]="true"
                [showRowLines]="true"
                [rowAlternationEnabled]="true"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [repaintChangesOnly]="true"
                [showBorders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                height="100%"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>

                <dxi-column
                  caption="Attendee Name"
                  [dataField]="AttendeeKeys.firstName"
                  cssClass="ag-crm-attendee-flight__cell-vertical-aligned"
                  [calculateDisplayValue]="calculateFullNameDisplayValue"
                  [sortOrder]="'asc'"
                >
                </dxi-column>

                <dxi-column caption="Attendee Type" [dataField]="AttendeeDrivingDataKeys.attendeeTitle"></dxi-column>

                <dxi-column caption="Reason" [dataField]="AttendeeDrivingDataKeys.reason"></dxi-column>

                <dxi-column
                  caption="Additional Request"
                  [dataField]="AttendeeDrivingDataKeys.additionalRequest"
                ></dxi-column>
              </dx-data-grid>
            </ng-container>
          </ng-container>
        </div>
      </dx-accordion>
    </ng-container>
  </dx-scroll-view>
</ng-container>
