<ag-shr-modal-window
  #conferenceStepInfoModalRef
  [title]="'Steps Details'"
  [width]="'100%'"
  [height]="'100%'"
  [isFullScreen]="true"
  [useScrollView]="false"
  [inProgress]="inProgress"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleSaveInfo()"
>
  <div
    *ngIf="conferenceStepInfoModalRef?.popupComponent?.visible ?? false"
    class="conference-steps-info-modal__container"
  >
    <dx-form [(formData)]="stepsConfigurationData" labelMode="floating" [validationGroup]="validationGroup">
      <ng-container *ngIf="stepsConfigurationData?.[ConferenceStepsConfigurationKeys.stepName] as stepName">
        <dxi-item itemType="group" [colCount]="12" [caption]="stepName | conferenceStepName">
          <dxi-item
            [dataField]="ConferenceStepsConfigurationKeys.title"
            [label]="{ text: 'Title' }"
            [colSpan]="6"
          ></dxi-item>

          <dxi-item
            [colSpan]="6"
            [dataField]="ConferenceStepsConfigurationKeys.template"
            [label]="{ text: 'Description Template' }"
            editorType="dxSelectBox"
            [editorOptions]="{
              items: templateLookup,
              valueExpr: 'value',
              displayExpr: 'description',
              placeholder: '',
              onValueChanged: handleTemplateValueChange
            }"
          ></dxi-item>

          <dxi-item [colSpan]="12">
            <div class="conference-steps-info-modal__template-container mat-typography" *dxTemplate>
              <h4>
                <b>{{stepsConfigurationData?.[ConferenceStepsConfigurationKeys.title]}}</b>
              </h4>

              <ng-container *var="stepsConfigurationData?.[ConferenceStepsConfigurationKeys.template] as template">
                <ng-container *ngIf="!!template; else emptyContent">
                  <shr-html-editor
                    class="mt-2"
                    [name]="ConferenceStepsConfigurationKeys.htmlContent"
                    [(value)]="
                      stepsConfigurationData && stepsConfigurationData[ConferenceStepsConfigurationKeys.htmlContent]
                    "
                    [label]="'Description Editor'"
                    [variables]="ConferenceStepsArgumentVariables"
                    [toolbarItemType]="[
                      HtmlEditorTypeKeys.fontStyle,
                      HtmlEditorTypeKeys.variable,
                      HtmlEditorTypeKeys.preview
                    ]"
                    (onShowPreview)="onShowPreview($event)"
                  ></shr-html-editor>
                </ng-container>

                <ng-template #emptyContent><p class="mb-0 ag-empty-text">No Description</p></ng-template>
              </ng-container>
            </div>
          </dxi-item>

          <!--Additional form item by steps-->
          <ng-container [ngSwitch]="stepName">
            <!-- RSVP -->
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.rsvpStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  items: rsvpFieldsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons'
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.rsvpAttendingLabel"
                [label]="{ text: 'Attending Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.sentPlaqueLabel"
                [label]="{ text: 'Sent a Plaque Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.awardNameLabel"
                [label]="{ text: 'Award Name Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.shippingAddressConfirmationLabel"
                [label]="{ text: 'Shipping Address Confirmation Label' }"
              >
              </dxi-item>
            </ng-container>
            <!-- Personal Info -->
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.registrantPersonalInformationStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedSections"
                [label]="{ text: 'Excluded Sections' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  items: personalInfoExcludedSectionsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons'
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  dataSource: personalInfoFieldsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons',
                  grouped: true,
                  searchEnabled: true
                }"
              ></dxi-item>
            </ng-container>
            <!-- Guest Configuration -->
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.registrantGuestConfigurationStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedSections"
                [label]="{ text: 'Excluded Sections' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  items: excludedSectionsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons'
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  dataSource: guestFieldsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons',
                  grouped: true,
                  searchEnabled: true
                }"
              ></dxi-item>
            </ng-container>
            <!-- Hotel Reservation -->
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.registrantHotelReservationStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  items: hotelReservationFieldsList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons'
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.hotelInfoAdditionalInfoLabel"
                [label]="{ text: 'Additional Info Label' }"
              >
              </dxi-item>
            </ng-container>
            <!-- Flight Information -->
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.registrantFlightInformationStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.flightBookedOnOwnRegTypes"
                [label]="{ text: 'Registration Types booking their own flights' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  dataSource: REGISTRATION_TYPE_LOOKUP,
                  displayExpr: LookupKeys.description,
                  valueExpr: LookupKeys.value,
                  showSelectionControls: true,
                  applyValueMode: 'useButtons',
                  searchEnabled: true
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  dataSource: flightInfoFieldsDataList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons',
                  grouped: true,
                  searchEnabled: true
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel"
                [label]="{ text: 'Passport Name Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel"
                [label]="{ text: 'Driver License Name Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="4"
                [dataField]="ConferenceStepsConfigurationKeys.mileageReimbursementAmount"
                [label]="{ text: 'Mileage Reimbursement Amount, $' }"
                editorType="dxNumberBox"
                [editorOptions]="{format: {type:'currency',currency:'USD', precision: 2}, min: 0}"
              >
              </dxi-item>
            </ng-container>
            <ng-container *ngSwitchCase="ConferenceRegistrationStepName.registrationGuestFlightInformationStep">
              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
                [label]="{ text: 'Excluded Fields' }"
                editorType="dxTagBox"
                [editorOptions]="{
                  dataSource: flightInfoGuestsFieldsDataList,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  showSelectionControls: true,
                  applyValueMode: 'useButtons',
                  grouped: true,
                  searchEnabled: true
                }"
              ></dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel"
                [label]="{ text: 'Passport Name Label' }"
              >
              </dxi-item>

              <dxi-item
                [colSpan]="12"
                [dataField]="ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel"
                [label]="{ text: 'Driver License Name Label' }"
              >
              </dxi-item>
            </ng-container>
          </ng-container>
        </dxi-item>
      </ng-container>
    </dx-form>
  </div>
</ag-shr-modal-window>

<dx-popup
  [showTitle]="true"
  title="Preview"
  width="80%"
  height="auto"
  [(visible)]="popupVisible"
  [showCloseButton]="true"
>
  <div class="conference-steps-info-modal__template-container mat-typography">
    <h4>
      <b>{{stepsConfigurationData?.[ConferenceStepsConfigurationKeys.title]}}</b>
    </h4>

    <dx-scroll-view class="conference-registration-wizard__scroll-view" [useNative]="false" [showScrollbar]="'always'">
      <span
        class="dx-widget mb-3"
        *ngIf="stepsConfigurationData?.[ConferenceStepsConfigurationKeys.htmlContent] as htmlContent"
      >
        <span
          [innerHTML]="
            htmlContent | conferenceTemplateWithArguments : (conferenceStepsTemplateArguments$ | async) | safeHTMLUrl
          "
        ></span>
      </span>
    </dx-scroll-view>
  </div>
</dx-popup>
