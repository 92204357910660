import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, from, lastValueFrom, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { Loader } from '@googlemaps/js-api-loader';
import { Address, AddressModelKeys } from '@ag-common-lib/public-api';
import { GOOGLE_MAPS_API_KEY } from '@ag-common-lib/lib/models/utils/google-maps-injection-token';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddressValidationResponse } from '@ag-common-lib/lib';

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  private apiKey = this.googleMapsApiKey;
  private ADDRESS_VALIDATION_API_URL = 'https://addressvalidation.googleapis.com/v1:validateAddress';

  private readonly loader = new Loader({
    apiKey: this.apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  private _sessionToken;
  private _resetSessionTokenTimeoutId;

  userPosition: { lat: number; lng: number };

  userPosition$ = new BehaviorSubject(null);

  readonly placesLib$ = from(this.loader.importLibrary('places')).pipe(shareReplay(1));
  readonly mapsLib$ = from(this.loader.importLibrary('maps')).pipe(shareReplay(1));
  readonly markerLib$ = from(this.loader.importLibrary('marker')).pipe(shareReplay(1));
  // markerLib$ = from(this.loader.importLibrary('v')).pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    @Inject(GOOGLE_MAPS_API_KEY) private googleMapsApiKey: string,
  ) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const userPosition = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.userPosition = userPosition;
        this.userPosition$.next(userPosition);
      });
    }
  }

  initMap = async (): Promise<google.maps.MapsLibrary> => {
    return firstValueFrom(this.mapsLib$);
  };

  initMarkers = async (): Promise<google.maps.MarkerLibrary> => {
    return firstValueFrom(this.markerLib$);
  };

  getDetails = async (placeId: string) => {
    this._sessionToken = null;

    const predictions = await lastValueFrom(
      this.placesLib$.pipe(
        mergeMap((placesLib): Promise<any> => {
          const placesService = new placesLib.PlacesService(document.createElement('div'));
          const request: google.maps.places.PlaceDetailsRequest = {
            placeId: placeId,
            fields: ['id', 'name', 'address_component', 'geometry.location'],
          };

          return new Promise(res => {
            placesService.getDetails(request, (place, status) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                res(null);
              }
              res(this.getAddress(placeId, place));
            });
          });
        }),

        catchError(() => {
          return of(null);
        }),
        take(1),
      ),
    );

    return predictions;
  };

  validateAddress(address: Address): Promise<AddressValidationResponse> {
    this._sessionToken = null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const addressLines = this.getAddressLines(address);
    const body = {
      address: {
        regionCode: 'US',
        locality: address[AddressModelKeys.city],
        administrativeArea: address[AddressModelKeys.state],
        postalCode: address[AddressModelKeys.zip],
        addressLines: addressLines,
      },
      enableUspsCass: true,
      previousResponseId: address?.[AddressModelKeys.validationResponse]?.responseId,
    };

    return lastValueFrom(
      this.http
        .post<AddressValidationResponse>(`${this.ADDRESS_VALIDATION_API_URL}?key=${this.apiKey}`, body, { headers })
        .pipe(take(1), tap(console.log)),
    );
  }

  getAddressLines(address: Address): string[] {
    const addressLines: string[] = [];

    if (address?.[AddressModelKeys.address1]) {
      addressLines.push(address?.[AddressModelKeys.address1]);
    }
    if (address?.[AddressModelKeys.address2]) {
      addressLines.push(`#(${address?.[AddressModelKeys.address2]})`);
    }

    // // Optionally include city, state, and ZIP on separate lines or combined
    // const cityStateZip = [
    //   address?.[AddressModelKeys.city],
    //   address?.[AddressModelKeys.state],
    //   address?.[AddressModelKeys.zip],
    // ]
    //   .filter(Boolean)
    //   .join(', ');

    // if (cityStateZip) {
    //   addressLines.push(cityStateZip);
    // }

    return addressLines;
  }

  private getAddress = (placeId: string, place: google.maps.places.PlaceResult) => {
    const location = place.geometry.location;
    const items = place?.address_components?.reduce(
      (acc, component) => {
        const types = new Set(component?.types);
        if (types.has('street_number')) {
          acc.streetNumber = component.long_name;
        }
        if (types.has('route')) {
          acc.route = component.long_name;
        }
        if (types.has('subpremise')) {
          acc.subpremise = component.long_name;
        }
        if (types.has('locality')) {
          acc.city = component.long_name;
        }
        if (types.has('administrative_area_level_1')) {
          acc.state = component.short_name; // State (use short_name for abbreviations like "CA" for California)
        }
        if (types.has('administrative_area_level_2')) {
          acc.county = component.short_name; // State (use short_name for abbreviations like "CA" for California)
        }
        if (types.has('country')) {
          acc.country = component.long_name; // Country
        }
        if (types.has('postal_code')) {
          acc.zipCode = component.long_name; // Postal code
        }

        return acc;
      },
      {
        streetNumber: null,
        route: null,
        subpremise: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        county: null,
      },
    );

    const address: Address = {
      [AddressModelKeys.geoPoint]: {
        [AddressModelKeys.lat]: location?.lat(),
        [AddressModelKeys.lng]: location?.lng(),
      },
    };

    address[AddressModelKeys.address1] = [items?.streetNumber, items?.route].filter(Boolean).join(' ');
    address[AddressModelKeys.address2] = items?.subpremise;
    address[AddressModelKeys.country] = items?.country;
    address[AddressModelKeys.county] = items?.county;
    address[AddressModelKeys.city] = items?.city;
    address[AddressModelKeys.state] = items?.state;
    address[AddressModelKeys.zip] = items?.zipCode;

    return address;
  };

  getGoogleSuggestions = async (searchValue: string): Promise<google.maps.places.AutocompletePrediction[]> => {
    const sessionToken = await this.getSessionToken();
    const predictions = await lastValueFrom(
      this.placesLib$.pipe(
        mergeMap((placesLib): Promise<google.maps.places.AutocompleteResponse> => {
          const service = new placesLib.AutocompleteService();
          const request: google.maps.places.AutocompletionRequest = {
            input: searchValue,
            locationBias: { lat: 33.9999638, lng: -84.0658614 }, // Alliance Group Head Office
            types: ['premise', 'street_address', 'street_number', 'subpremise'],
            sessionToken,

            componentRestrictions: { country: ['us'] },
          };

          return service.getPlacePredictions(request);
        }),
        map(data => data?.predictions ?? []),

        catchError(() => {
          return of([]);
        }),
        take(1),
      ),
    );

    return predictions;
  };

  private getSessionToken = async () => {
    if (!this._sessionToken) {
      clearTimeout(this._resetSessionTokenTimeoutId);

      this._sessionToken = await lastValueFrom(
        this.placesLib$.pipe(
          map(lib => new lib.AutocompleteSessionToken()),
          take(1),
        ),
      );

      this._resetSessionTokenTimeoutId = setTimeout(
        () => {
          this._sessionToken = null;
        },
        1000 * 60 * 4,
      );
    }

    return this._sessionToken;
  };
}
