import { Pipe, PipeTransform } from '@angular/core';
import {
  AirlinePreference,
  AirlinePreferenceKeys,
} from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/flight-preference.model';

@Pipe({
  name: 'preferredAirline',
  standalone: true,
})
export class PreferredAirlinePipe implements PipeTransform {
  transform(
    airlinePreferences: AirlinePreference[],
    field: AirlinePreferenceKeys,
    isFirstChoice: boolean = true,
  ): unknown {
    return airlinePreferences.find(airlinePreferece =>
      isFirstChoice
        ? airlinePreferece[AirlinePreferenceKeys.isFirstChoiceAirline]
        : airlinePreferece[AirlinePreferenceKeys.isSecondChoiceAirline],
    )[field];
  }
}
