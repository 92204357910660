import { Injectable } from '@angular/core';

@Injectable()
export class FlightPreferenceFormService {
  constructor() {}

  getFormData = initialData => {
    if (!Array.isArray(initialData?.airlinePreferences)) {
      initialData.airlinePreferences = [];
    }

    const formData = new Proxy(initialData ?? { airlinePreferences: [] }, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          Reflect.set(target, prop, value, receiver);
        }
        return true;
      },
    });

    return formData;
  };
}
