import { Component, HostBinding, Input } from '@angular/core';
import {
  AirlinePreference,
  AirlinePreferenceKeys,
  FlightPreference,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/flight-preference.model';
import { FlightPreferenceFormService } from './flight-preference-form.service';
import {
  Airlines,
  AIRLINES_LOOKUP,
  FlightInfoKeys,
  SEAT_PREFERENCE_LOOKUP,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/flight-information.model';
import { LookupKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/lookup.model';
import { BehaviorSubject } from 'rxjs';
import { EditingStartEvent, RowValidatingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'ag-shr-flight-preference-form',
  templateUrl: './flight-preference-form.component.html',
  styleUrl: './flight-preference-form.component.scss',
})
export class FlightPreferenceFormComponent {
  @HostBinding('class') className = 'dietary-considerations-form';
  @Input() set flightPreference(data: Partial<FlightPreference>) {
    this._formData = this.flightPreferenceService.getFormData(data);
    this.updateFilteredAirlines();
  }

  get flightPreference() {
    return this._formData;
  }
  @Input() isReadOnly: boolean = false;
  @Input() validationGroup: string;

  filteredAirlines$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private _formData: FlightPreference = { airlinePreferences: [] };

  constructor(private flightPreferenceService: FlightPreferenceFormService) {}

  protected readonly AIRLINES_LOOKUP = AIRLINES_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly SEAT_PREFERENCE_LOOKUP = SEAT_PREFERENCE_LOOKUP;
  protected readonly Airlines = Airlines;

  protected onInitNewRow = e => {
    if (e.data?.['__KEY__']) {
      delete e.data?.['__KEY__'];
    }

    Object.assign(e.data, { isFirstChoiceAirline: false, isSecondChoiceAirline: false });
  };

  updateFilteredAirlines(airlineToSkip?: Airlines) {
    const selectedAirlines = this.flightPreference.airlinePreferences
      .map(pref => pref.airline)
      .filter(airline => (airlineToSkip ? airline !== airlineToSkip : true));

    const filteredAirlines = [...AIRLINES_LOOKUP].filter(airline => !selectedAirlines.includes(airline.value));
    this.filteredAirlines$.next(filteredAirlines);
  }

  isFirstChoiceDisabled(cellInfo: any): boolean {
    const isSecondChoiceSelected = cellInfo.data.isSecondChoiceSelected;

    const isFirstChoiceAlreadySelected = this.flightPreference.airlinePreferences.some(
      airline => airline.isFirstChoiceAirline && airline !== cellInfo.data,
    );

    return isSecondChoiceSelected || isFirstChoiceAlreadySelected;
  }

  isSecondChoiceDisabled(cellInfo: any): boolean {
    const isFirstChoiceAirline = cellInfo.data.isFirstChoiceAirline;

    const isSecondChoiceAlreadySelected = this.flightPreference.airlinePreferences.some(
      airline => airline.isSecondChoiceAirline && airline !== cellInfo.data,
    );

    return isSecondChoiceAlreadySelected || isFirstChoiceAirline;
  }

  onValueChanged = (event, cellInfo) => {
    cellInfo.setValue(event.value);
  };

  onRowSaved() {
    this.updateFilteredAirlines();
  }

  onEditingStart(event: EditingStartEvent) {
    this.updateFilteredAirlines(event.data.airline);
  }

  protected onRowValidating = (e: RowValidatingEvent<AirlinePreference>): void => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const isValid = !(data.isFirstChoiceAirline && data.isSecondChoiceAirline);

    e.isValid = isValid;

    const dataGrid = e.component;
    const rowIndex = dataGrid.getRowIndexByKey(e.key);

    if (!isValid) {
      dataGrid
        .getCellElement(rowIndex, AirlinePreferenceKeys.isFirstChoiceAirline)
        .classList.add('dx-validator', 'dx-datagrid-invalid');
      dataGrid
        .getCellElement(rowIndex, AirlinePreferenceKeys.isSecondChoiceAirline)
        .classList.add('dx-validator', 'dx-datagrid-invalid');
    }

    if (!data.airline) {
      dataGrid
        .getCellElement(rowIndex, AirlinePreferenceKeys.airline)
        .classList.add('dx-validator', 'dx-datagrid-invalid');
    }
  };
}
