import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { AttendeeHotelReservationService } from './attendee-hotel-reservation.service';
import {
  Agent,
  AIDS_LOOKUP,
  BED_PREFERENCE_LOOKUP,
  CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
  ConferencePossibleStayPeriod,
  ConferenceRegistrationCommonTaskStatus,
  Constants,
  Entity,
  EntityPermissionActivityKeys,
  HotelReservation,
  HotelReservationKeys,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  Registrant,
  RegistrantModelKeys,
  RequestOutcomeState,
} from 'ag-common-lib/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { InitializedEvent } from 'devextreme/ui/form';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import {
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationLogType,
  ConferenceRegistrationRequestLogKey,
  ConferenceRegistrationSupportLogKey,
  RequestLogStatus,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import { AttendeeNoteModalComponent } from '../attendee-note-modal/attendee-note-modal.component';
import { LoadOptions } from 'devextreme/data';
import {
  AssignedOwnerDetails,
  AssignedOwnerDetailsKeys,
} from 'ag-common-svc/lib/components/ag-assign-owner-viewer/config/ag-assign-owner.config';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-attendee-hotel-reservation',
  templateUrl: './attendee-hotel-reservation.component.html',
  styleUrls: ['./attendee-hotel-reservation.component.scss'],
  providers: [AttendeeHotelReservationService, UserRolesService],
})
export class AttendeeHotelReservationComponent {
  @HostBinding('class') className = 'attendee-hotel-reservation';
  @ViewChild('approveDifferBookingDatesRequestModalRef', { static: false })
  approveDifferBookingDatesRequestModalComponent: ModalWindowComponent;
  @ViewChild('rejectDifferBookingDatesRequestModalRef', { static: false })
  rejectDifferBookingDatesRequestModalComponent: ModalWindowComponent;
  @ViewChild('approveAdditionalRoomRequestModalRef', { static: false })
  approveAdditionalRoomRequestModalComponent: ModalWindowComponent;
  @ViewChild('rejectAdditionalRoomRequestModalRef', { static: false })
  rejectAdditionalRoomRequestModalComponent: ModalWindowComponent;
  @ViewChild(AttendeeNoteModalComponent, { static: false }) attendeeNoteModalComponent!: AttendeeNoteModalComponent;

  @Input() hotelReservation: Partial<HotelReservation>;
  @Input() validationGroup: string;

  protected HotelReservationKeys = HotelReservationKeys;
  protected RequestOutcomeState = RequestOutcomeState;
  protected readonly LookupKeys = LookupKeys;
  protected conferencePossibleStayPeriod$: Observable<ConferencePossibleStayPeriod> =
    this.attendeeHotelReservationService.conferencePossibleStayPeriod$;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly bedPreferenceLookup = BED_PREFERENCE_LOOKUP;
  protected readonly aidsLookup = AIDS_LOOKUP;
  protected colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
  };
  protected isRequestedDifferentHotelCheckInCheckOutDates$ =
    this.attendeeDetailsModalService.isRequestedDifferentHotelCheckInCheckOutDates$;

  protected formSize$ = new BehaviorSubject(null);
  protected requestInProgress$ = new BehaviorSubject<boolean>(false);
  protected note = '';
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;

  protected readonly taskStatus = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  conferenceDbId$ = new BehaviorSubject<string>(null);
  registrantDbId$ = new BehaviorSubject<string>(null);

  protected hotelTaskStatus$ = this.attendeeHotelReservationService.hotelTaskStatus$;
  protected readonly isRequestedAdditionalRoom$ = this.attendeeHotelReservationService.isRequestedAdditionalRoom$;
  protected readonly additionalRoomRequestOutcome$ = this.attendeeHotelReservationService.additionalRoomRequestOutcome$;
  protected readonly requestDifferBookingDatesOutcome$ =
    this.attendeeHotelReservationService.requestDifferBookingDatesOutcome$;
  protected agentsLoadOptions: LoadOptions<Agent>;
  protected assignOwnerList$ = this.attendeeDetailsModalService.assignOwnerList$;

  constructor(
    private attendeeHotelReservationService: AttendeeHotelReservationService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
  ) {
    this.attendeeDetailsModalService.registrant$.subscribe((conferenceRegistration: Registrant) => {
      this.conferenceDbId$.next(conferenceRegistration?.[RegistrantModelKeys.conferenceDbId]);
      this.registrantDbId$.next(conferenceRegistration?.[BaseModelKeys.dbId]);
    });
  }

  protected onOwnerChange = (data: AssignedOwnerDetails) => {
    return this.attendeeHotelReservationService.updateHotelTask({
      assignedTo: data?.[AssignedOwnerDetailsKeys.assignedTo] ?? null,
      assignedPersonNote: data?.[AssignedOwnerDetailsKeys.assignedToNote] ?? null,
    });
  };

  protected onTaskStatusChanged = (flyTaskStatus: ConferenceRegistrationCommonTaskStatus, e: ValueChangedEvent) => {
    if (flyTaskStatus === e?.value) {
      return;
    }
    this.attendeeHotelReservationService.updateHotelTask({
      status: e?.value ?? null,
    });
  };

  protected handleAdditionalRoomRequest = (requestOutcomeState: RequestOutcomeState) => {
    this.note = '';
    switch (requestOutcomeState) {
      case RequestOutcomeState.approved:
        this.approveAdditionalRoomRequestModalComponent.showModal();
        return;
      case RequestOutcomeState.rejected:
        this.rejectAdditionalRoomRequestModalComponent.showModal();
        return;
    }
  };

  protected handleDifferBookingDates = (requestOutcomeState: RequestOutcomeState) => {
    this.note = '';
    switch (requestOutcomeState) {
      case RequestOutcomeState.approved:
        this.approveDifferBookingDatesRequestModalComponent.showModal();
        return;
      case RequestOutcomeState.rejected:
        this.rejectDifferBookingDatesRequestModalComponent.showModal();
        return;
    }
  };

  protected handleAdditionalRoomRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    this.requestInProgress$.next(true);
    this.attendeeHotelReservationService
      .handleAdditionalRoomRequestOutcome(requestOutcomeState)
      .then(() => {
        requestOutcomeState === RequestOutcomeState.approved
          ? this.approveAdditionalRoomRequestModalComponent.hideModal()
          : this.rejectAdditionalRoomRequestModalComponent.hideModal();
        const logTitle =
          'Additional Room Request ' + (requestOutcomeState === RequestOutcomeState.approved ? 'Approved' : 'Declined');
        this.logAdditionalRequestOutcome(requestOutcomeState, logTitle);
      })
      .catch(() => {})
      .finally(() => this.requestInProgress$.next(false));
  };

  protected handleDifferBookingDatesRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    this.requestInProgress$.next(true);
    this.attendeeHotelReservationService
      .handleDifferBookingDatesRequestOutcome(requestOutcomeState)
      .then(() => {
        requestOutcomeState === RequestOutcomeState.approved
          ? this.approveDifferBookingDatesRequestModalComponent.hideModal()
          : this.rejectDifferBookingDatesRequestModalComponent.hideModal();
        const logTitle =
          'Differ Check-In Check-Out Dates Request ' +
          (requestOutcomeState === RequestOutcomeState.approved ? 'Approved' : 'Declined');

        this.logAdditionalRequestOutcome(requestOutcomeState, logTitle);
      })
      .catch(() => {})
      .finally(() => this.requestInProgress$.next(false));
  };

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  private logAdditionalRequestOutcome = (requestOutcomeState: RequestOutcomeState, logTitle: string) => {
    this.attendeeHotelReservationService.saveLog({
      [ConferenceRegistrationLogKeys.type]: ConferenceRegistrationLogType.specialRequest,
      [ConferenceRegistrationLogKeys.data]: {
        [ConferenceRegistrationRequestLogKey.status]:
          requestOutcomeState === RequestOutcomeState.approved ? RequestLogStatus.approved : RequestLogStatus.rejected,
        [ConferenceRegistrationSupportLogKey.title]: logTitle,
        [ConferenceRegistrationSupportLogKey.date]: new Date(),
        [ConferenceRegistrationSupportLogKey.details]: this.note,
      },
    });
  };
}
