import { Component, Input, HostBinding, ViewChild } from '@angular/core';
import {
  AgentKeys,
  Conference,
  ConferenceKeys,
  ElasticSearchAgent,
  EMAIL_ACTION_TRIGGERS_LOOKUP,
  EmailAddressKeys,
  SENDER_CONFIGURATION_MAP,
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplateModel,
  EmailTemplatesDetailsMap,
  EmailTemplatesModels,
  EVENTS_EMAIL_TEMPLATES,
  LookupKeys,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
  AGMedia,
  AGMediaKeys,
} from 'ag-common-lib/public-api';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import DataSource from 'devextreme/data/data_source';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';
import CustomStore from 'devextreme/data/custom_store';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import { AgentEmailAddressesService, AuthService, ConferenceRegistrantsService } from 'ag-common-svc/public-api';
import { RowRemovingEvent, ColumnButtonClickEvent } from 'devextreme/ui/data_grid';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  EventInquiryRequest,
  EventInquiryRequestKeys,
} from 'ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { filter, firstValueFrom, mergeMap, Observable, shareReplay } from 'rxjs';
import { ConferenceModalService } from '../conference-form.service';
import { EventInquiryRequestsService } from 'ag-common-svc/lib/services/event-inquire-requests.service';
import { RegistrationParticipationEmailService } from 'ag-common-svc/lib/services/registration-participation-email.service';
import { AttendeesEmailsService } from 'src/app/ag-crm/registrations/attendees-emails.service';
import { ConferenceRegistrationEmailCampaignService } from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';
import { EmailTemplateConfigurationFormService } from './email-template-configuration-form.service';
import { MediaUploaderTabs } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.models';
import { AgMediaUploaderModalComponent } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.component';

@Component({
  selector: 'ag-crm-conference-email-sender-form',
  templateUrl: './conference-email-sender-form.component.html',
  styleUrls: ['./conference-email-sender-form.component.scss'],
  providers: [
    ConferenceRegistrationEmailCampaignService,
    AttendeesEmailsService,
    EmailTemplateConfigurationFormService,
  ],
})
export class ConferenceEmailSenderFormComponent {
  @HostBinding('class') className = 'conference-email-sender-form';
  @ViewChild('sendTestEmailModalRef', { static: true }) sendTestEmailModalComponent: ModalWindowComponent;
  @ViewChild('editEmailTemplateModalRef', { static: false }) editEmailTemplateModalComponent: ModalWindowComponent;
  @ViewChild('uploadAttachmentModalRef', { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent;
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly LookupKeys = LookupKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly EmailTemplatesModels = EmailTemplatesModels;
  protected readonly RegistrantModelKeys = RegistrantModelKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly EventInquiryRequestKeys = EventInquiryRequestKeys;
  protected readonly EmailTemplateConfigurationKeys = EmailTemplateConfigurationKeys;
  protected readonly MediaUploaderTabs = MediaUploaderTabs;
  protected readonly AGMediaKeys = AGMediaKeys;
  protected readonly emailActionTriggersLookup = EMAIL_ACTION_TRIGGERS_LOOKUP;
  protected readonly emailSendersLookup = SENDER_CONFIGURATION_MAP;
  protected readonly emailTemplatesLookup = EVENTS_EMAIL_TEMPLATES;
  protected readonly agentsEmailAddressesDataSource = this.agentEmailAddressesService.agentsEmailAddressesDataSource;
  protected dataSource = new DataSource({
    paginate: true,
    pageSize: 250,
    store: new CustomStore({
      key: 'dbId',
      loadMode: 'processed',
      load: async loadOptions => {
        const filter: any[] = [];

        if (loadOptions.filter) {
          this.conference?.[ConferenceKeys.bccEmail]?.forEach((item: any) => {
            if (filter?.length) {
              filter.push('or');
            }
            filter.push([AgentKeys.email_addresses, DxFilterOperators.contains, item?.address]);
          });
        }

        if (loadOptions.searchValue) {
          [AgentKeys.email_addresses, AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name].forEach(field => {
            if (filter?.length) {
              filter.push('or');
            }

            filter.push([field, DxFilterOperators.contains, loadOptions.searchValue]);
          });
        }

        loadOptions.filter = filter;
        const data = [];
        const response = await this.agentElasticSearchService.getFromElastic(loadOptions);
        const aggregations = response?.aggregations;
        const emailsAggregations = aggregations?.[AgentKeys.email_addresses];

        response?.data?.forEach((agent: ElasticSearchAgent) => {
          const fullName = getAgentFullName(agent);
          agent?.[AgentKeys.email_addresses]?.forEach(emailAddress => {
            const address = emailAddress?.[EmailAddressKeys.address];
            data.push({
              name: fullName,
              address: address,
            });
          });
        });

        return {
          data,
          totalCount: emailsAggregations?.doc_count,
        };
      },
    }),
  });

  protected emailTemplateConfigurationFormData: EmailTemplateConfiguration;

  protected conferenceRegistrants$: Observable<Registrant[]> = this.conferenceModalService.conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceRegistrantsService.getRegistrantsByConferenceId(conferenceDbId);
    }),
    shareReplay(1),
  );
  protected eventInquiryRequests$: Observable<EventInquiryRequest[]> = this.conferenceModalService.conferenceDbId$.pipe(
    mergeMap(conferenceDbId => {
      return this.eventInquiryRequestsService.getList(conferenceDbId);
    }),
    shareReplay(1),
  );

  protected saveEmailConfigurationInProgress$ = this.emailTemplateConfigurationFormService.inProgress$;
  protected testEmailFormData: {
    to: string[];
    emailTemplate: EmailTemplateModel;
    emailTemplateConfiguration: EmailTemplateConfiguration;
    payload?: Registrant | EventInquiryRequest;
  };

  constructor(
    private authService: AuthService,
    private attendeesEmailsService: AttendeesEmailsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private eventInquiryRequestsService: EventInquiryRequestsService,
    private conferenceModalService: ConferenceModalService,
    private agentElasticSearchService: AgentElasticSearchService,
    private agentEmailAddressesService: AgentEmailAddressesService,
    private registrationParticipationEmailService: RegistrationParticipationEmailService,
    private emailTemplateConfigurationFormService: EmailTemplateConfigurationFormService,
  ) {}

  protected attachmentsCalculateDisplayValue = (emailTemplateConfiguration: EmailTemplateConfiguration) => {
    const attachments = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.attachments];

    if (!attachments?.length) {
      return null;
    }

    return `${attachments?.length} attachment(s)`;
  };

  protected handlerSendTestEmail = async (e: ColumnButtonClickEvent<EmailTemplateConfiguration>) => {
    const emailTemplateConfiguration: EmailTemplateConfiguration = e?.row?.data;
    const agent = await firstValueFrom(this.authService.loggedInAgent$);
    const primaryEmail = agent?.[AgentKeys.p_email];
    const templateName = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.templateName];
    const emailTemplate = EmailTemplatesDetailsMap.get(templateName);

    this.testEmailFormData = {
      to: [primaryEmail].filter(Boolean),
      emailTemplate,
      emailTemplateConfiguration,
      payload: null,
    };

    this.sendTestEmailModalComponent.showModal();
  };

  protected sendToEmail = async () => {
    try {
      const emailRecipientTestMode = this.testEmailFormData.to;
      const emailTemplate = this.testEmailFormData.emailTemplate;
      const emailTemplateConfiguration = this.testEmailFormData.emailTemplateConfiguration;

      switch (emailTemplate.model) {
        case EmailTemplatesModels.registrationInquiry:
          const eventInquiryRequest = this.testEmailFormData.payload;
          const emailActionTrigger = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.trigger];
          const eventInquiryRequestDbId = eventInquiryRequest?.[BaseModelKeys.dbId];
          const eventInquiryDbId = eventInquiryRequest?.[EventInquiryRequestKeys.eventInquiryDbId];

          await this.registrationParticipationEmailService.sendRegistrationParticipationEmail({
            isTestEmail: true,
            eventInquiryDbId,
            emailRecipientTestMode,
            emailActionTrigger,
            eventInquiryRequestDbId,
          });
          break;

        case EmailTemplatesModels.registrant:
          const registrant: Registrant = this.testEmailFormData.payload;

          await this.attendeesEmailsService.sendEmails(emailTemplateConfiguration, {
            registrants: [registrant],
            isTestEmail: true,
            emailRecipientTestMode,
          });
          break;
      }

      this.sendTestEmailModalComponent.forceCloseModal();
    } catch (error) {
      debugger;
    }
  };

  protected emailTemplateDisplayExpr = item => {
    const template = item?.templateName;
    const templateConfiguration = EmailTemplatesDetailsMap.get(template);
    const title = templateConfiguration?.title;

    return title;
  };

  protected setTemplateCellValue = (updatedData, value) => {
    const eventName = this.conference?.[ConferenceKeys.eventName];
    const templateData = EmailTemplatesDetailsMap.get(value);

    Object.assign(updatedData, {
      [EmailTemplateConfigurationKeys.templateName]: value,
      [EmailTemplateConfigurationKeys.subject]: [eventName, templateData?.title].filter(Boolean).join(' - '),
    });
  };

  protected calculateTemplateNameDisplayValue = (templateConfiguration: EmailTemplateConfiguration) => {
    const template = templateConfiguration?.[EmailTemplateConfigurationKeys.templateName];
    const templateData = EmailTemplatesDetailsMap.get(template);

    if (!templateData) {
      return template;
    }

    return [templateData?.forEvent, templateData?.title].filter(Boolean).join(' - ');
  };

  protected addEmailTemplate = (): void => {
    this.emailTemplateConfigurationFormData = this.emailTemplateConfigurationFormService.getFormData(this.conference);
    this.editEmailTemplateModalComponent.showModal();
  };

  protected editEmailTemplate = ({ row: { data } }): void => {
    this.emailTemplateConfigurationFormData = this.emailTemplateConfigurationFormService.getFormData(
      this.conference,
      data,
    );
    this.editEmailTemplateModalComponent.showModal();
  };

  protected handleSaveEmailTemplateConfiguration = async () => {
    const cancel = await this.emailTemplateConfigurationFormService.handleSaveEmailTemplateConfiguration();
    debugger;
    if (cancel) {
      return;
    }

    this.editEmailTemplateModalComponent.forceCloseModal();
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    this.emailTemplateConfigurationFormService.onRowRemoving(this.conference, e);
  };

  protected canSendTestEmail = (e: ColumnButtonClickEvent<EmailTemplateConfiguration>) => {
    return !e?.row?.isEditing;
  };

  protected handelAddAttachment = (): void => {
    this.mediaUploaderComponent.showModal();
  };

  protected onMediaChanged = (media: AGMedia): void => {
    this.emailTemplateConfigurationFormService.addAttachment(media);
  };
}
