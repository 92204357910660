<dx-form [(formData)]="conference" labelMode="floating" [colCount]="1" [readOnly]="isReadonlyMode">
  <dxi-item [dataField]="ConferenceKeys.bccEmail" [label]="{ text: 'BCC' }">
    <div *dxTemplate>
      <dx-tag-box
        #tagBoxRef
        [dataSource]="agentsEmailAddressesDataSource"
        [value]="conference?.[ConferenceKeys.bccEmail]"
        (valueChange)="conference && (conference[ConferenceKeys.bccEmail] = $event)"
        [searchEnabled]="true"
        [showDropDownButton]="true"
        [showClearButton]="true"
        [multiline]="false"
        [acceptCustomValue]="false"
        [label]="'BCC'"
        labelMode="floating"
        [valueExpr]="AgentKeys.email_addresses"
        [displayExpr]="AgentKeys.email_addresses"
        class="attendees-box"
        [placeholder]="''"
        [maxDisplayedTags]="3"
        [readOnly]="isReadonlyMode"
      >
        <div *dxTemplate="let data of 'item'">
          <div style="padding: 5px 0">
            <strong>{{ data?.[AgentKeys.email_addresses] }}</strong>
          </div>

          <div *ngFor="let agent of data?.agents; let i = index" style="padding: 5px 0 0 0px">
            {{
              [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
                | fullName
                | empty: "Unknown Agent"
            }}
            <i *ngIf="agent?.p_email === data?.[AgentKeys.email_addresses]" class="dx-icon-agi-key"></i>
          </div>
        </div>
      </dx-tag-box>
    </div>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-data-grid
        [dataSource]="conference?.[ConferenceKeys.emailTemplates]"
        [showRowLines]="true"
        [rowAlternationEnabled]="true"
        [showColumnHeaders]="true"
        [columnAutoWidth]="true"
        (onRowRemoving)="onRowRemoving($event)"
        [wordWrapEnabled]="true"
        height="100%"
      >
        <dxo-editing
          mode="row"
          [useIcons]="true"
          [allowUpdating]="!isReadonlyMode"
          [allowDeleting]="!isReadonlyMode"
          [allowAdding]="!isReadonlyMode"
          [newRowPosition]="'last'"
        >
        </dxo-editing>

        <dxo-toolbar>
          <dxi-item location="before">
            <span *dxTemplate class="dx-form-group-caption">Event Email Templates</span>
          </dxi-item>

          <dxi-item
            location="after"
            widget="dxButton"
            cssClass="ag-grid__toolbar-control"
            [options]="{ icon: 'add', onClick: addEmailTemplate }"
          >
          </dxi-item>
        </dxo-toolbar>

        <dxi-column
          [dataField]="EmailTemplateConfigurationKeys.templateName"
          caption="Template Name"
          [calculateDisplayValue]="calculateTemplateNameDisplayValue"
          [setCellValue]="setTemplateCellValue"
          editCellTemplate="templateEditorCellTmp"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>

          <div *dxTemplate="let cell of 'templateEditorCellTmp'">
            <dx-select-box
              [dataSource]="emailTemplatesLookup"
              valueExpr="value"
              displayExpr="description"
              [grouped]="true"
              [value]="cell?.value"
              (valueChange)="cell.setValue($event)"
            ></dx-select-box>
          </div>
        </dxi-column>

        <dxi-column [dataField]="EmailTemplateConfigurationKeys.subject" caption="Subject"> </dxi-column>

        <dxi-column [dataField]="EmailTemplateConfigurationKeys.sender" caption="Sender" width="120">
          <dxo-lookup [dataSource]="emailSendersLookup" valueExpr="value" displayExpr="description"></dxo-lookup>
        </dxi-column>

        <dxi-column
          [dataField]="EmailTemplateConfigurationKeys.trigger"
          caption="Action Trigger"
          width="200"
          [editorOptions]="{
            showClearButton: true,
            placeholder: 'None',
          }"
        >
          <dxo-lookup
            [dataSource]="emailActionTriggersLookup"
            [valueExpr]="LookupKeys.value"
            [displayExpr]="LookupKeys.description"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column
          [dataField]="EmailTemplateConfigurationKeys.attachments"
          [calculateDisplayValue]="attachmentsCalculateDisplayValue"
          caption="Attachments"
        >
        </dxi-column>

        <dxi-column type="buttons" *ngIf="!isReadonlyMode">
          <dxi-button [icon]="'edit'" [hint]="'Edit'" [onClick]="editEmailTemplate"></dxi-button>

          <dxi-button name="delete"></dxi-button>

          <dxi-button
            name="testEmail"
            icon="email"
            [visible]="canSendTestEmail"
            [onClick]="handlerSendTestEmail"
          ></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dxi-item>
</dx-form>

<ag-shr-modal-window
  #editEmailTemplateModalRef
  [width]="'50%'"
  [height]="'auto'"
  title="Add / Edit Email Template"
  [inProgress]="saveEmailConfigurationInProgress$ | async"
  (onSaveClick)="handleSaveEmailTemplateConfiguration()"
>
  <ng-container *ngIf="editEmailTemplateModalRef?.popupComponent?.visible ?? false">
    <dx-form
      #sendTEstEmailFormRef
      class="ag-assign-owner__form"
      [(formData)]="emailTemplateConfigurationFormData"
      labelMode="floating"
    >
      <dxi-item
        [dataField]="EmailTemplateConfigurationKeys.templateName"
        editorType="dxSelectBox"
        [label]="{ text: 'Template Name' }"
        [editorOptions]="{
          dataSource: emailTemplatesLookup,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description,
          searchEnabled: true,
          placeholder: '',
          grouped: true,
        }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item [dataField]="EmailTemplateConfigurationKeys.subject" [label]="{ text: 'Subject' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="2">
        <dxi-item
          [dataField]="EmailTemplateConfigurationKeys.sender"
          editorType="dxSelectBox"
          [label]="{ text: 'Sender' }"
          [editorOptions]="{
            dataSource: emailSendersLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
          }"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item
          [dataField]="EmailTemplateConfigurationKeys.trigger"
          editorType="dxSelectBox"
          [label]="{ text: 'Action Trigger' }"
          [editorOptions]="{
            dataSource: emailActionTriggersLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
          }"
        ></dxi-item>
      </dxi-item>
    </dx-form>

    <dx-data-grid
      [dataSource]="emailTemplateConfigurationFormData?.[EmailTemplateConfigurationKeys.attachments]"
      [showRowLines]="true"
      [rowAlternationEnabled]="true"
      [showColumnHeaders]="true"
      [columnAutoWidth]="true"
      [showBorders]="true"
      [wordWrapEnabled]="true"
      [noDataText]="'No Attachments'"
      class="mt-2"
      height="100%"
    >
      <dxo-editing
        mode="row"
        [useIcons]="true"
        [allowUpdating]="!isReadonlyMode"
        [allowDeleting]="!isReadonlyMode"
        [allowAdding]="!isReadonlyMode"
        [newRowPosition]="'last'"
      >
      </dxo-editing>

      <dxo-toolbar>
        <dxi-item location="before">
          <span *dxTemplate class="conference-email-sender-form__attachments-grid-title">Attachments</span>
        </dxi-item>

        <dxi-item
          location="after"
          widget="dxButton"
          cssClass="ag-grid__toolbar-control"
          [options]="{ icon: 'add', onClick: handelAddAttachment }"
        >
        </dxi-item>
      </dxo-toolbar>

      <dxi-column [dataField]="AGMediaKeys.fileName" caption="Name"> </dxi-column>

      <dxi-column [dataField]="AGMediaKeys.contentType" caption="Type" [allowExporting]="false"> </dxi-column>

      <dxi-column type="buttons" *ngIf="!isReadonlyMode">
        <dxi-button name="edit"></dxi-button>

        <dxi-button name="delete"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </ng-container>
</ag-shr-modal-window>

<ag-shr-modal-window
  #sendTestEmailModalRef
  [width]="450"
  [height]="'auto'"
  title="Send Test Email"
  [actionTitle]="'SEND'"
  (onSaveClick)="sendToEmail()"
>
  <ng-container *ngIf="sendTestEmailModalRef?.popupComponent?.visible ?? false">
    <dx-form #sendTEstEmailFormRef class="ag-assign-owner__form" [(formData)]="testEmailFormData" labelMode="floating">
      <dxi-item [dataField]="'to'" [label]="{ text: 'BCC' }">
        <div *dxTemplate>
          <dx-tag-box
            #tagBoxRef
            [dataSource]="agentsEmailAddressesDataSource"
            [value]="testEmailFormData?.to"
            (valueChange)="testEmailFormData && (testEmailFormData.to = $event)"
            [searchEnabled]="true"
            [showDropDownButton]="true"
            [showClearButton]="true"
            [multiline]="false"
            [acceptCustomValue]="false"
            [label]="'To'"
            labelMode="floating"
            [valueExpr]="AgentKeys.email_addresses"
            [displayExpr]="AgentKeys.email_addresses"
            class="attendees-box"
            [placeholder]="''"
            [maxDisplayedTags]="3"
            [readOnly]="isReadonlyMode"
          >
            <div *dxTemplate="let data of 'item'">
              <div style="padding: 5px 0">
                <strong>{{ data?.[AgentKeys.email_addresses] }}</strong>
              </div>

              <div *ngFor="let agent of data?.agents; let i = index" style="padding: 5px 0 0 0px">
                {{
                  [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
                    | fullName
                    | empty: "Unknown Agent"
                }}
                <i *ngIf="agent?.p_email === data?.[AgentKeys.email_addresses]" class="dx-icon-agi-key"></i>
              </div>
            </div>
          </dx-tag-box>
        </div>
      </dxi-item>

      <dxi-item
        *ngIf="EmailTemplatesModels.registrant === testEmailFormData.emailTemplate.model"
        [dataField]="'payload'"
        editorType="dxSelectBox"
        [label]="{ text: 'Event Registration' }"
        [editorOptions]="{
          dataSource: conferenceRegistrants$ | async,
          displayExpr: [RegistrantModelKeys.data, RegistrantKeys.inviteeEmail] | path,
          searchEnabled: true,
          placeholder: '',
        }"
      ></dxi-item>

      <dxi-item
        *ngIf="EmailTemplatesModels.registrationInquiry === testEmailFormData.emailTemplate.model"
        [dataField]="'payload'"
        editorType="dxSelectBox"
        [label]="{ text: 'Event Application' }"
        [editorOptions]="{
          dataSource: eventInquiryRequests$ | async,
          displayExpr: EventInquiryRequestKeys.email,
          searchEnabled: true,
          placeholder: '',
        }"
      ></dxi-item>
    </dx-form>
  </ng-container>
</ag-shr-modal-window>

<!-- [mediaPathPrefix]="mediaPathPrefix$ | async" -->
<ag-shr-media-uploader-modal
  #uploadAttachmentModalRef
  [accept]="[]"
  [width]="'360px'"
  [height]="'360px'"
  [placeholder]="'Click or Drag an Attachment'"
  (mediaChanged)="onMediaChanged($event)"
  [mediaSources]="[MediaUploaderTabs.FromFile]"
></ag-shr-media-uploader-modal>
