import { Injectable } from '@angular/core';
import {
  ExcursionInfoTableData,
  ExcursionInfoTableDataKeys,
} from '../components/excursions-info-table/excursions-info-table.models';
import {
  AssociationKeys,
  Conference,
  ConferenceKeys,
  EmailAddressKeys,
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplates,
  ExcursionConfigurationsEmailKeys,
  ExcursionOptionKey,
  FlightInfoKeys,
  GuestData,
  GuestKeys,
  Headshot,
  HeadshotKeys,
  HotelReservationKeys,
  LocalDateTimeString,
  MediaSize,
  NotifyEmail,
  NotifyEmailKeys,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrationSummaryEmail,
  RegistrationSummaryEmailExcursion,
  RegistrationSummaryEmailExcursionGroup,
  RegistrationSummaryEmailKeys,
  RegistrationSummaryEmailRegistrationDetail,
  RegistrationSummaryEmailTransaction,
  RequestOutcomeKeys,
  StripeTransaction,
  StripeTransactionKeys,
  TRANSACTIONS_STATUSES_LOOKUP,
} from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../services/cloud-functions.service';
import { WasabiImgPipe } from '../../shared/pipes/wasabi-img.pipe';
import { ToastrService } from 'ngx-toastr';
import { ConferenceGuestsService } from './conference-guests.service';
import { ConferenceTransactionsService } from './conference-stripe-transactions.service';
import { ConferenceRegistrantsService } from './conference-registrants/conference-registrants.service';
import { firstValueFrom, forkJoin, take } from 'rxjs';
import { SuffixPipe } from '../../shared/pipes/suffix.pipe';
import { ConferenceService } from './conference.service';
import { convertSelectedExcursionsToList, getExcursionsMap } from '../utils/excursions-data.utils';
import { IsRequestedDifferentHotelCheckInCheckOutDatesPipe } from '../../shared/pipes/is-requested-different-hotel-check-in-check-out-dates.pipe';
import { stringFromDate } from '../utils/string-from-date';
import { ExcursionConfigurationDisplayValuePipe } from '../../shared/pipes/excursion-configuration-display-value.pipe';
import { ExcursionConfigurationDisplayNamePipe } from '../../shared/pipes/excursion-configuration-display-name.pipe';
import { isEmpty } from 'lodash';
import { getFullAddress } from '../../shared/utils/full-address';

export class SendRegistrationSummaryEmailPayload {
  conferenceDbId: string;
  registrantDbId: string;
  isTestEmail?: boolean;
  emailRecipientTestMode?: string[];
}

@Injectable()
export class RegistrationSummaryEmailService {
  constructor(
    private cloudFunctionsService: CloudFunctionsService,
    private wasabiImgPipe: WasabiImgPipe,
    private suffixPipe: SuffixPipe,
    private toastrService: ToastrService,
    private excursionConfigurationDisplayValuePipe: ExcursionConfigurationDisplayValuePipe,
    private excursionConfigurationDisplayNamePipe: ExcursionConfigurationDisplayNamePipe,
    private conferenceService: ConferenceService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private conferenceGuestsService: ConferenceGuestsService,
    private conferenceTransactionsService: ConferenceTransactionsService,
    private isRequestedDifferentHotelCheckInCheckOutDatesPipe: IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
  ) {}

  async sendRegistrationSummaryEmail(
    emailTemplate: EmailTemplateConfiguration,
    payload: SendRegistrationSummaryEmailPayload,
  ): Promise<void> {
    const summaryEmail = await this.generateRegistrationSummaryEmail(emailTemplate, payload);

    await this.cloudFunctionsService
      .sendConferenceRegistrationSummary(summaryEmail)
      .then(() => {
        this.toastrService.success('Registration Summary was sent via email!');
      })
      .catch(error => {
        console.log('Email Error: ', error);
        this.toastrService.error('Registration Summary is not sent via email!');
      });
  }

  generateRegistrationSummaryEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    { conferenceDbId, registrantDbId, isTestEmail, emailRecipientTestMode }: SendRegistrationSummaryEmailPayload,
  ): Promise<RegistrationSummaryEmail> => {
    const { registrant, guests, transactions } = await firstValueFrom(
      forkJoin({
        registrant: this.conferenceRegistrantsService.getDocumentData(conferenceDbId, registrantDbId).pipe(take(1)),
        guests: this.conferenceGuestsService.getList(conferenceDbId, registrantDbId).pipe(take(1)),
        transactions: this.conferenceTransactionsService.getList(conferenceDbId, registrantDbId).pipe(take(1)),
      }),
    );

    const registrantData = registrant?.[RegistrantModelKeys.data];
    if (!registrantData) {
      throw new Error('No registrant data found');
    }
    const conference = await firstValueFrom(this.conferenceService.getDocumentData(conferenceDbId));
    const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
    const excursions = await this.createExcursionsSummaryEmail(conference, registrantData, guests, true);
    const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
    const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
    const requestedCheckInDate = hotelReservation?.[HotelReservationKeys.requestedCheckInDate];
    const requestedCheckOutDate = hotelReservation?.[HotelReservationKeys.requestedCheckOutDate];
    const hasAdditionalRoomRequest =
      hotelReservation?.[HotelReservationKeys.additionalRoomRequested] &&
      !hotelReservation?.[HotelReservationKeys.additionalRoomRequestOutcome]?.[RequestOutcomeKeys.state];
    const hasDifferBookingDatesRequest =
      this.isRequestedDifferentHotelCheckInCheckOutDatesPipe.transform(
        checkInDate,
        checkOutDate,
        requestedCheckInDate,
        requestedCheckOutDate,
      ) && !hotelReservation?.[HotelReservationKeys.requestDifferBookingDatesOutcome]?.[RequestOutcomeKeys.state];

    return {
      ...this.getCommonEmailData({
        emailTemplate,
        registrantData,
        conference,
        isTestEmail,
        emailRecipientTestMode,
      }),
      [RegistrationSummaryEmailKeys.flightItineraryEmail]: '',
      [RegistrationSummaryEmailKeys.eventName]: conference?.[ConferenceKeys.eventName] ?? 'conference',
      [RegistrationSummaryEmailKeys.hotelName]: conference?.[ConferenceKeys.hotelName] ?? 'Hyat Ziva Cap Cana',
      [RegistrationSummaryEmailKeys.hotelState]: conference?.[ConferenceKeys.hotelState] ?? 'Cap Cana',
      [RegistrationSummaryEmailKeys.coordinatorEmail]: isEmpty(conference?.[ConferenceKeys.eventCoordinatorEmail])
        ? 'events@alliancegrouplife.com'
        : conference?.[ConferenceKeys.eventCoordinatorEmail],
      [RegistrationSummaryEmailKeys.coordinatorPrimaryPhoneNumber]: isEmpty(
        conference?.[ConferenceKeys.eventCoordinatorPhone],
      )
        ? '8153389668'
        : conference?.[ConferenceKeys.eventCoordinatorPhone],
      [RegistrationSummaryEmailKeys.checkInDate]: stringFromDate(checkInDate),
      [RegistrationSummaryEmailKeys.checkOutDate]: stringFromDate(checkOutDate),
      [RegistrationSummaryEmailKeys.requestedCheckInDate]: stringFromDate(requestedCheckInDate),
      [RegistrationSummaryEmailKeys.requestedCheckOutDate]: stringFromDate(requestedCheckOutDate),
      [RegistrationSummaryEmailKeys.excursions]: excursions,
      [RegistrationSummaryEmailKeys.hasAdditionalRoomRequest]: hasAdditionalRoomRequest,
      [RegistrationSummaryEmailKeys.hasDifferBookingDatesRequest]: hasDifferBookingDatesRequest,
      [RegistrationSummaryEmailKeys.registrationDetails]: this.createRegistrationDetailSummaryEmail(
        registrantData,
        guests,
      ),
      [RegistrationSummaryEmailKeys.transactions]: this.createTransactionsSummaryEmail(transactions),
    };
  };

  generateRegistrationBeforeYouGoEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    { conferenceDbId, registrantDbId, isTestEmail, emailRecipientTestMode }: SendRegistrationSummaryEmailPayload,
  ): Promise<RegistrationSummaryEmail> => {
    const { registrant, guests, conference } = await firstValueFrom(
      forkJoin({
        registrant: this.conferenceRegistrantsService.getDocumentData(conferenceDbId, registrantDbId).pipe(take(1)),
        guests: this.conferenceGuestsService.getList(conferenceDbId, registrantDbId).pipe(take(1)),
        conference: this.conferenceService.getDocumentData(conferenceDbId).pipe(take(1)),
      }),
    );
    const registrantData = registrant?.[RegistrantModelKeys.data];

    const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
    const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
    const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];

    const flightInformation = registrantData?.[RegistrantKeys.flightInformation];
    const travelDateArrivalDate = flightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate;
    const travelDateDepartureDate = flightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate;
    const eventStartDate = conference?.[ConferenceKeys.startDate];
    const eventEndDate = conference?.[ConferenceKeys.endDate];
    const hotelPhoneNumber = conference?.[ConferenceKeys.hotelPrimaryPhone];

    const excursions = await this.createExcursionsSummaryEmail(conference, registrantData, guests);

    return {
      ...this.getCommonEmailData({
        emailTemplate,
        registrantData,
        conference,
        isTestEmail,
        emailRecipientTestMode,
      }),
      [ExcursionConfigurationsEmailKeys.excursions]: excursions,
      [RegistrationSummaryEmailKeys.eventStartDate]: stringFromDate(eventStartDate),
      [RegistrationSummaryEmailKeys.eventEndDate]: stringFromDate(eventEndDate),
      [RegistrationSummaryEmailKeys.hotelPhoneNumber]: hotelPhoneNumber,
      [ExcursionConfigurationsEmailKeys.hotelCheckInDate]: stringFromDate(checkInDate),
      [ExcursionConfigurationsEmailKeys.hotelCheckOutDate]: stringFromDate(checkOutDate),
      [RegistrationSummaryEmailKeys.eventName]: conference?.[ConferenceKeys.eventName] ?? 'conference',
      [RegistrationSummaryEmailKeys.hotelName]: conference?.[ConferenceKeys.hotelName] ?? 'Hyat Ziva Cap Cana',
      [RegistrationSummaryEmailKeys.hotelState]: conference?.[ConferenceKeys.hotelState] ?? 'Cap Cana',
      [RegistrationSummaryEmailKeys.hotelAddress]: getFullAddress(conference?.[ConferenceKeys.hotelAddress]) ?? '-',
      [RegistrationSummaryEmailKeys.coordinatorEmail]: isEmpty(conference?.[ConferenceKeys.eventCoordinatorEmail])
        ? 'events@alliancegrouplife.com'
        : conference?.[ConferenceKeys.eventCoordinatorEmail],
      [RegistrationSummaryEmailKeys.coordinatorPrimaryPhoneNumber]: isEmpty(
        conference?.[ConferenceKeys.eventCoordinatorPhone],
      )
        ? '8153389668'
        : conference?.[ConferenceKeys.eventCoordinatorPhone],
      [ExcursionConfigurationsEmailKeys.travelDates]: `${stringFromDate(travelDateArrivalDate)} - ${stringFromDate(
        travelDateDepartureDate,
      )}`,
    };
  };

  generateActivitiesEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    { conferenceDbId, registrantDbId, isTestEmail, emailRecipientTestMode }: SendRegistrationSummaryEmailPayload,
  ): Promise<any> => {
    const { registrant, guests, conference } = await firstValueFrom(
      forkJoin({
        registrant: this.conferenceRegistrantsService.getDocumentData(conferenceDbId, registrantDbId).pipe(take(1)),
        guests: this.conferenceGuestsService.getList(conferenceDbId, registrantDbId).pipe(take(1)),
        conference: this.conferenceService.getDocumentData(conferenceDbId).pipe(take(1)),
      }),
    );
    const registrantData = registrant?.[RegistrantModelKeys.data];
    const excursions = await this.createExcursionsSummaryEmail(conference, registrantData, guests);
    // TODO add model
    return {
      ...this.getCommonEmailData({
        emailTemplate,
        registrantData,
        conference,
        isTestEmail,
        emailRecipientTestMode,
      }),
      excursions: excursions,
    };
  };

  private getCommonEmailData = ({
    emailTemplate,
    registrantData,
    conference,
    isTestEmail = false,
    emailRecipientTestMode,
  }: {
    isTestEmail?: boolean;
    emailRecipientTestMode?: string[];
    emailTemplate: EmailTemplateConfiguration;
    registrantData: RegistrantData;
    conference: Conference;
  }) => {
    const contextCommonData: NotifyEmail = {
      [NotifyEmailKeys.imagePath]: null,
      [NotifyEmailKeys.firstName]: registrantData[RegistrantKeys.firstName],
      [NotifyEmailKeys.lastName]: registrantData[RegistrantKeys.lastName],
    };
    const summaryEmailTemplate =
      emailTemplate?.[EmailTemplateConfigurationKeys.templateName] ??
      EmailTemplates.notifyEmailConferenceRegistrationSummary;
    const subject = emailTemplate?.[EmailTemplateConfigurationKeys.subject];
    const attachments = emailTemplate?.[EmailTemplateConfigurationKeys.attachments];
    const eventCoordinatorEmail = conference?.[ConferenceKeys.eventCoordinatorEmail] ?? 'events@alliancegrouplife.com';

    contextCommonData[NotifyEmailKeys.summaryEmailTemplate] = summaryEmailTemplate;
    contextCommonData[NotifyEmailKeys.attachments] = attachments;
    contextCommonData[NotifyEmailKeys.replyTo] = eventCoordinatorEmail;

    const wasabiImagePath = conference?.[ConferenceKeys.welcomeImagePath] ?? null;

    if (wasabiImagePath) {
      const imgFullPath = this.getImageUrl(wasabiImagePath);

      contextCommonData[NotifyEmailKeys.imagePath] = imgFullPath;
    }

    const summaryEmail =
      registrantData?.[RegistrantKeys.primaryEmailAddress]?.[EmailAddressKeys.address] ??
      registrantData?.[RegistrantKeys.inviteeEmail];
    const hasDiffPrimaryEmail = summaryEmail !== registrantData[RegistrantKeys.inviteeEmail];
    const summaryCCEmail: string[] = [];
    if (hasDiffPrimaryEmail) {
      summaryCCEmail.push(registrantData[RegistrantKeys.inviteeEmail]);
    }
    if (registrantData[RegistrantKeys.ccEmail]) {
      summaryCCEmail.push(registrantData[RegistrantKeys.ccEmail]);
    }
    const bccEmail = conference?.[ConferenceKeys.bccEmail];

    if (isTestEmail) {
      let testModeSubject = `AG_MAILING_TEST ${subject} `;

      testModeSubject += `\n to ${summaryEmail}`;
      summaryCCEmail?.forEach(ccTo => {
        testModeSubject += `\n cc ${ccTo}`;
      });
      bccEmail?.forEach(ccTo => {
        testModeSubject += `\n bcc ${ccTo}`;
      });

      return Object.assign(contextCommonData, {
        [NotifyEmailKeys.email]: emailRecipientTestMode,
        [NotifyEmailKeys.subjectTitle]: testModeSubject,
      });
    }

    return Object.assign(contextCommonData, {
      [NotifyEmailKeys.email]: summaryEmail,
      [NotifyEmailKeys.subjectTitle]: subject,
      [NotifyEmailKeys.ccEmail]: summaryCCEmail,
      [NotifyEmailKeys.bccEmail]: bccEmail,
    });
  };

  private getImageUrl(wasabiImage: Headshot): string {
    const url = this.wasabiImgPipe.transform(wasabiImage?.wasabiPath, {
      mediaSize: MediaSize.origin,

      cropperPayload: wasabiImage?.[HeadshotKeys.imageCropperPayload] ?? null,
    });

    return url;
  }

  private createExcursionsSummaryEmail = async (
    conference: Conference,
    registrantData: RegistrantData,
    guestsData: GuestData[],
    includePreferences = false,
  ): Promise<RegistrationSummaryEmailExcursion[]> => {
    const groupsMap = new Map<LocalDateTimeString, RegistrationSummaryEmailExcursionGroup[]>();
    const registrantFullName = await this.getFullName({
      firstName: registrantData[RegistrantKeys.firstName],
      lastName: registrantData[RegistrantKeys.lastName],
      suffixId: registrantData[RegistrantKeys.suffix],
    });
    const excursions = conference?.[ConferenceKeys.excursions];
    const { excursionsMap } = getExcursionsMap(excursions);
    const registrantSelectedExcursions = registrantData?.[RegistrantKeys.selectedExcursions];
    const registrantExcursionsList = convertSelectedExcursionsToList(excursionsMap, registrantSelectedExcursions);

    const collect = (name: string, data: ExcursionInfoTableData) => {
      const date = data?.[ExcursionInfoTableDataKeys.excursionDate];
      const details = [];
      if (includePreferences) {
        Object.entries(data[ExcursionInfoTableDataKeys.selectedPreferences] ?? {}).forEach(
          ([preferenceName, preferenceOption]) => {
            const item = `${preferenceName}: ${preferenceOption?.[ExcursionOptionKey.name]}`;

            details.push(item);
          },
        );
      }

      Object.entries(data[ExcursionInfoTableDataKeys.configurations] ?? {}).forEach(
        ([configurationName, configurationOption]) => {
          const displayName = this.excursionConfigurationDisplayNamePipe.transform(configurationName);
          const displayValue = this.excursionConfigurationDisplayValuePipe.transform(
            configurationOption?.value,
            configurationOption?.dataType,
          );
          const item = ` ${displayName}: ${displayValue}`;
          details.push(item);
        },
      );

      const excursion: RegistrationSummaryEmailExcursionGroup = {
        [RegistrationSummaryEmailKeys.excursionAttendee]: name,
        [RegistrationSummaryEmailKeys.excursionName]: data?.[ExcursionInfoTableDataKeys.excursionName],
        [RegistrationSummaryEmailKeys.excursionPreferences]: details.filter(Boolean).join(', '),
      };

      if (groupsMap.has(date)) {
        groupsMap.get(date).push(excursion);
        return;
      }

      groupsMap.set(date, [excursion]);
    };

    registrantExcursionsList.forEach(item => {
      collect(registrantFullName, item);
    });

    for (const guest of guestsData) {
      const guestFullName = await this.getFullName({
        firstName: guest[AssociationKeys.firstName],
        lastName: guest[AssociationKeys.lastName],
        suffixId: guest[AssociationKeys.suffix],
      });
      const guestSelectedExcursions = guest?.[GuestKeys.selectedExcursions];
      const guestExcursionsList = convertSelectedExcursionsToList(excursionsMap, guestSelectedExcursions);

      guestExcursionsList.forEach(item => {
        collect(guestFullName, item);
      });
    }

    const list: RegistrationSummaryEmailExcursion[] = [];
    groupsMap.forEach((excursions, date) => {
      list.push({
        [RegistrationSummaryEmailKeys.excursionsGroupedDate]: stringFromDate(date, 'EEEE, LLLL d, yyyy'),
        [RegistrationSummaryEmailKeys.excursionsGroup]: excursions,
      });
    });

    console.log('list', list);

    if (!list?.length) {
      return null;
    }

    return list;
  };

  private async getFullName({ firstName, lastName, suffixId }) {
    const suffix = await firstValueFrom(this.suffixPipe.transform(suffixId));
    return [firstName, lastName, suffix].filter(Boolean).join(' ');
  }

  private createTransactionsSummaryEmail = (
    transactions: StripeTransaction[],
  ): RegistrationSummaryEmailTransaction[] => {
    if (!transactions?.length) {
      return null;
    }
    const transactionsStatusesLookup = TRANSACTIONS_STATUSES_LOOKUP;
    return transactions.map(item => ({
      [RegistrationSummaryEmailKeys.transactionDate]: stringFromDate(item?.[StripeTransactionKeys.paymentDate]),
      [RegistrationSummaryEmailKeys.transactionTotal]: this.formatAmount(item?.[StripeTransactionKeys.amount]),
      [RegistrationSummaryEmailKeys.transactionStatus]: transactionsStatusesLookup.find(
        status => status.value === item?.[StripeTransactionKeys.status],
      )?.description,
    }));
  };

  private createRegistrationDetailSummaryEmail(
    registrantData: RegistrantData,
    guestsData: GuestData[],
  ): RegistrationSummaryEmailRegistrationDetail[] {
    const registrant = {
      [AssociationKeys.firstName]: registrantData?.[RegistrantKeys.firstName],
      [AssociationKeys.lastName]: registrantData?.[RegistrantKeys.lastName],
      [RegistrationSummaryEmailKeys.registrationType]: 'Qualifier',
    };
    const guests = guestsData.map(guest => {
      const isComplimentary = guest?.[GuestKeys.isComplimentary];
      return {
        [AssociationKeys.firstName]: guest?.[RegistrantKeys.firstName],
        [AssociationKeys.lastName]: guest?.[RegistrantKeys.lastName],
        [RegistrationSummaryEmailKeys.registrationType]: `${isComplimentary ? 'Complimentary' : 'Additional'} Guest`,
      };
    });

    return [registrant, ...guests];
  }

  private formatAmount(number: number): string {
    return number?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}
