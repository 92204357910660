import { Pipe, PipeTransform } from '@angular/core';
import {
  ConferenceRegistrationCommonTaskStatus,
  EVENT_REGISTRATIONS_TASKS_STATUS_WITH_NO_STATUS_LOOKUP,
} from 'ag-common-lib/public-api';

@Pipe({ name: 'conferenceTaskStatus' })
export class ConferenceRegistrationTakStatusPipe implements PipeTransform {
  transform(status: ConferenceRegistrationCommonTaskStatus) {
    return EVENT_REGISTRATIONS_TASKS_STATUS_WITH_NO_STATUS_LOOKUP.find(lookup => lookup?.value === status);
  }
}
