import { Injectable } from '@angular/core';
import {
  AgencyKeys,
  Agent,
  AgentKeys,
  AGENTS_COLLECTION_NAME,
  ElasticSearchAgent,
  EmailAddressKeys,
  SupportedCollections,
} from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../cloud-functions.service';
import { BaseElasticSearchService } from './base-elastic-search-service';
import { AgencyService } from '../agency.service';
import { combineLatest, defer, from, map, shareReplay } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import {
  AggregationsAggregationContainer,
  AggregationsCompositeAggregation,
  QueryDslQueryContainer,
  SearchRequest,
} from '@elastic/elasticsearch/lib/api/types';
import { DxFilterOperators } from '@ag-common-lib/lib';
import { getAgentFullName } from 'ag-common-svc/lib/utils/agent-data.util';

@Injectable({ providedIn: 'root' })
export class AgentElasticSearchService extends BaseElasticSearchService<Agent> {
  agentIdToNameMap$ = from(
    defer(async () => {
      const loadOptions: LoadOptions = {
        filter: [AgentKeys.p_agent_id, DxFilterOperators.doNotEqual, ''],
        group: [
          { selector: AgentKeys.p_agent_id },
          { selector: AgentKeys.p_email },
          { selector: AgentKeys.p_agent_first_name },
          { selector: AgentKeys.p_agent_last_name },
        ],
        userData: { isLoadingAll: true },
      };

      const response = await this.getFromElastic(loadOptions);
      const agentIdToNameMap = new Map<string, string>();

      response.data.forEach(group => {
        const agentId = group?.key?.[AgentKeys.p_agent_id];
        const fullName = getAgentFullName(group?.key);

        agentIdToNameMap.set(agentId, fullName);
      });

      return agentIdToNameMap;
    }),
  ).pipe(shareReplay(1));

  constructor(
    protected cloudFunctionsService: CloudFunctionsService,
    agencyService: AgencyService,
  ) {
    super(SupportedCollections.agents, SupportedCollections.agents);

    this.defaultSorting = [{ [AgentKeys.p_agent_first_name]: 'asc' }, { [AgentKeys.p_agent_last_name]: 'asc' }, '_doc'];

    const agencyNameMapping$ = agencyService.agenciesNamesByAgencyId$;
    this.runtimeMappings$ = combineLatest({
      mga_name: agencyNameMapping$.pipe(
        map(params => {
          const source = `
            def value = field('p_mga_id').get('');
            if (params.containsKey(value)) {
              emit(params[value]);
            } else {
              emit(value);
            }
          `.replace(/\n|\s{2,}/gm, '');
          return { source, params };
        }),
      ),
      agency_name: agencyNameMapping$.pipe(
        map(params => {
          const source = `
            def value = field('p_agency_id').get('');

            if (params.containsKey(value)) {
              emit(params[value]);
            } else {
              emit('');
            }
          `.replace(/\n|\s{2,}/gm, '');
          return { source, params };
        }),
      ),
    });

    this.resolveRuntimeMappings();
  }

  async getById(id: string): Promise<Agent> {
    const agent = await super.getById(id);
    if (!agent) {
      return null;
    }

    return Object.assign(agent, {
      [AgentKeys.p_agent_name]: getAgentFullName(agent),
    });
  }

  async getByIds(ids: string[]): Promise<Agent[]> {
    const agents = await super.getByIds(ids);
    if (!agents) {
      return [];
    }

    return agents.map(agent =>
      Object.assign(agent, {
        [AgentKeys.p_agent_name]: getAgentFullName(agent),
      }),
    );
  }

  // getAllFromElastic = async (options?: LoadOptions): Promise<Array<ElasticSearchAgent>> => {
  //   const agents = [];

  //   const getItems = async () => {
  //     const params = Object.assign({ skip: agents?.length, take: 500 }, options);
  //     const response = await this.getFromElastic(params);

  //     if (!response || !response?.data?.length) {
  //       return;
  //     }
  //     agents.push(...response.data);

  //     if (agents?.length === response.totalCount) {
  //       return;
  //     }

  //     return getItems();
  //   };

  //   await getItems();

  //   return agents;
  // };

  getDataSource = (options?: LoadOptions) => {
    return new DataSource({
      paginate: true,
      pageSize: 50,
      map: data => {
        return Object.assign({}, data, {
          [AgentKeys.p_agent_name]: getAgentFullName(data),
        });
      },
      store: this.getStore(options),
    });
  };

  getStore = (options?: LoadOptions) => {
    return new CustomStore({
      key: 'dbId',
      byKey: id => {
        return this.getById(id);
      },
      load: async loadOptions => {
        const params = { ...options, ...loadOptions };
        const response = await this.getFromElastic(params);

        return response;
      },
    });
  };

  getAgentsGeoPoints = async () => {
    const searchRequest: SearchRequest = {
      index: this.defaultIndex, // Specify your index name here
      size: 20, // No documents in the response, just aggregations
      query: {
        bool: {
          must: [
            {
              nested: {
                path: 'addresses',
                query: {
                  exists: {
                    field: 'addresses.geo_point',
                  },
                },
              },
            },
            {
              nested: {
                path: 'addresses',
                query: {
                  geo_distance: {
                    distance: '250km',
                    'addresses.geo_point': { lat: 33.999905277682096, lon: -84.06595997030261 },
                  },
                },
              },
            },
            // {
            //   nested: {
            //     path: 'addresses',
            //     query: {
            //       wildcard: {
            //         'addresses.address1': { value: '*3361*' },
            //       },
            //     },
            //   },
            // },
          ],
        },

        // nested: {
        //   path: 'addresses',
        //   query: {
        //     exists: {
        //       field: 'address1',
        //     },
        //   },
        // },
      },
      // aggregations: {
      //   groups: {
      //     composite: {
      //       size: 500,
      //       sources: [
      //         // {
      //         //   [PolicyTransactionKeys.agentId]: {
      //         //     terms: {
      //         //       field: PolicyTransactionKeys.agentId,
      //         //       missing_bucket: true,
      //         //       missing_order: 'default',
      //         //     },
      //         //   },
      //         // },
      //       ],
      //     },
      //     // aggregations: revenueAggregation,
      //   },
      //   // count: {
      //   //   cardinality: {
      //   //     field: PolicyTransactionKeys.agentId,
      //   //   },
      //   // },
      // },
    };

    let searchAfter;
    const data = [];
    const getItems = async () => {
      if (searchAfter) {
        searchRequest.aggregations.groups.composite.after = searchAfter;
      }

      const response = await this.cloudFunctionsService.searchWithElastic(searchRequest);
      debugger;
      const aggregations: any = response?.data?.aggregations;
      const groupCount = aggregations?.count?.value ?? null;
      const groups = aggregations?.groups;
      const lastHit = groups?.after_key;
      const buckets = groups?.buckets;

      if (!buckets?.length) {
        return;
      }
      searchAfter = lastHit;

      data.push(...buckets);

      if (!lastHit || data?.length === groupCount) {
        return;
      }

      return getItems();
    };
    await getItems();

    return data;
  };

  protected getAggregations = (loadOptions: LoadOptions): Record<string, AggregationsAggregationContainer> => {
    const userData = loadOptions?.userData;
    const lastHit = userData?.lastHit;
    const composite: AggregationsCompositeAggregation = {
      size: loadOptions?.take ?? 20,
      sources: [
        {
          email: {
            terms: {
              field: 'email_addresses.address',
              order: 'asc',
              missing_bucket: true,
            },
          },
        },
      ],
    };

    const lastHitEmailAddress = lastHit?.[AgentKeys.email_addresses];
    if (lastHitEmailAddress) {
      const email = lastHitEmailAddress;
      composite.after = { email };
    }

    return {
      email_addresses: {
        nested: {
          path: 'email_addresses',
        },
        aggregations: {
          groupedEmails: {
            composite,
            // TODO at some reason aggregation failed for some queries if we include Agent name fields. workaround in aggregation normalizer
            // aggregations: {
            //   agents: {
            //     reverse_nested: {},
            //     aggregations: {
            //       data: {
            //         top_hits: {
            //           _source: [
            //             AgentKeys.p_agent_first_name,
            //             AgentKeys.p_agent_last_name,
            //             AgentKeys.p_email,
            //             BaseModelKeys.dbId,
            //           ],
            //         },
            //       },
            //     },
            //   },
            // },
          },
        },
      },
    };
  };

  normalizeAggregations = async (response: any) => {
    const aggregations = response?.aggregations;
    const emailsAggregations = aggregations?.[AgentKeys.email_addresses];
    const groupedEmails = emailsAggregations?.groupedEmails;
    const buckets = groupedEmails?.buckets;
    const sumOtherDocCount = groupedEmails?.length;
    const totalCount = buckets?.length + sumOtherDocCount;

    const agentsMap = new Map();
    let agentsRequestSize = 0;

    const data = buckets?.map(bucket => {
      const key = bucket?.key;
      const email = key?.email;
      const agentsCount = bucket?.doc_count;
      agentsRequestSize += agentsCount;
      const agents = [];

      agentsMap.set(email, agents);

      return {
        [AgentKeys.email_addresses]: email,
        agentsCount,
        agents,
      };
    });

    const filter = [];

    agentsMap.forEach((_value, key) => {
      if (filter?.length) {
        filter.push('or');
      }

      filter.push([AgentKeys.email_addresses, DxFilterOperators.equal, key]);
    });

    const agentsRequest = await this.getFromElastic({ filter, take: agentsRequestSize * 2 });
    agentsRequest.data?.forEach((agent: ElasticSearchAgent) => {
      agent?.[AgentKeys.email_addresses]?.forEach(emailAddress => {
        const address = emailAddress?.[EmailAddressKeys.address];

        if (!agentsMap.has(address)) {
          return;
        }
        agentsMap.get(address).push(agent);
      });
    });

    return {
      data,
      totalCount,
    };
  };
}
