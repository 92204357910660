<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Flight Preferences'"
    [isEditVisible]="canEdit"
    (clickEdit)="showEditorModal()"
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <div class="col-1-7 text-start">
      <b>Pref. Airline 1</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Loyalty # (Pref. Airline 1)</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Pref. Airline 2</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Loyalty # (Pref. Airline 2)</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Known Traveler #</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Seat Pref.</b>
    </div>
    <div class="col-1-7 text-start">
      <b>Pref. Departure Airport</b>
    </div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-1-7 text-start">
      {{
        flightPreferenceFormDetails?.[FlightPreferenceKeys.airlinePreferences]
          | preferredAirline: AirlinePreferenceKeys.airline
          | empty
      }}
    </div>
    <div class="col-1-7 text-start">
      {{
        flightPreferenceFormDetails?.[FlightPreferenceKeys.airlinePreferences]
          | preferredAirline: AirlinePreferenceKeys.loyaltyNumber
          | empty
      }}
    </div>
    <div class="col-1-7 text-start">
      {{
        flightPreferenceFormDetails?.[FlightPreferenceKeys.airlinePreferences]
          | preferredAirline: AirlinePreferenceKeys.airline : false
          | empty
      }}
    </div>
    <div class="col-1-7 text-start">
      {{
        flightPreferenceFormDetails?.[FlightPreferenceKeys.airlinePreferences]
          | preferredAirline: AirlinePreferenceKeys.loyaltyNumber : false
          | empty
      }}
    </div>
    <div class="col-1-7 text-start">
      {{ flightPreferenceFormDetails?.[FlightInfoKeys.knownTravelerNumber] | empty }}
    </div>
    <div class="col-1-7 text-start">
      {{ flightPreferenceFormDetails?.[FlightInfoKeys.seatPreference] | empty }}
    </div>
    <div class="col-1-7 text-start">
      {{ flightPreferenceFormDetails?.[FlightInfoKeys.preferredDepartureAirport] | empty }}
    </div>
  </div>
</div>

<ag-shr-modal-window
  [width]="'50vw'"
  [height]="'auto'"
  #flightPreferenceModalRef
  [title]="'Edit Flight Preferences'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgentUpdates()"
  (onPopupClose)="handleClosePopup($event)"
>
  <ag-shr-flight-preference-form
    [validationGroup]="validationGroup"
    [flightPreference]="flightPreferenceFormDetails"
  ></ag-shr-flight-preference-form>
</ag-shr-modal-window>
