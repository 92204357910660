<header class="ag-header">
  <img
    alt="AG logo"
    class="ag-header__logo"
    src="assets/ag-logo-sm-blue.png"
    (click)="navigateToPath(AppRoutes.Home)"
  />

  <span class="ag-header__title" (click)="navigateToPath(AppRoutes.Home)">
    {{ title }}
  </span>

  <dx-toolbar class="ag-header__toolbar" (onInitialized)="onToolbarInitialized($event)" [items]="toolbarItems$ | async">
    <!-- <dxi-item location="before" widget="dxMenu" locateInMenu="auto" [options]="menuOptions$ | async"></dxi-item> -->
  </dx-toolbar>

  <dx-drop-down-button
    icon="agi-user"
    keyExpr="value"
    [dropDownOptions]="userBoxdropDownOptions$ | async"
    displayExpr="description"
    [items]="[
      {
        value: AppRoutes.Profile,
        description: 'Profile',
        visible: true,
      },
      {
        value: AppRoutes.Logout,
        description: 'Logout',
        visible: true,
      },
    ]"
    (onItemClick)="onUserMenuItemClick($event)"
  ></dx-drop-down-button>
</header>
