import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTMLUrl',
})
export class SafeHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(htmlVal) {
    // console.log('SafeHTMLPipe htmlVal', htmlVal);

    return this.sanitizer.bypassSecurityTrustHtml(htmlVal);
  }
}
