import { AssociationKeys } from '../utils/association.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { NotifyEmail } from './notify-email.model';
import { ExcursionConfigurationsEmail, ExcursionConfigurationsEmailKeys } from './excursion-configurations-email.model';

export enum RegistrationSummaryEmailKeys {
  hotelName = 'hotelName',
  hotelState = 'hotelState',
  hotelAddress = 'hotelAddress',
  coordinatorEmail = 'coordinatorEmail',
  coordinatorPrimaryPhoneNumber = 'coordinatorPrimaryPhoneNumber',
  eventName = 'eventName',
  checkInDate = 'checkInDate',
  checkOutDate = 'checkOutDate',
  requestedCheckInDate = 'requestedCheckInDate',
  requestedCheckOutDate = 'requestedCheckOutDate',
  eventStartDate = 'eventStartDate',
  eventEndDate = 'eventEndDate',
  hotelPhoneNumber = 'hotelPhoneNumber',
  flightItineraryEmail = 'flightItineraryEmail',
  registrationDetails = 'registrationDetails',
  transactions = 'transactions',
  excursions = 'excursions',
  hasDifferBookingDatesRequest = 'hasDifferBookingDatesRequest',
  hasAdditionalRoomRequest = 'hasAdditionalRoomRequest',
  //
  registrationType = 'registrationType',
  // transaction table
  transactionDate = 'transactionDate',
  transactionTotal = 'transactionTotal',
  transactionStatus = 'transactionStatus',
  // excursions table
  excursionsGroupedDate = 'excursionsGroupedDate',
  excursionsGroup = 'excursionsGroup',
  excursionAttendee = 'excursionAttendee',
  excursionName = 'excursionName',
  excursionPreferences = 'excursionPreferences',
}

export interface RegistrationSummaryEmailRegistrationDetail {
  [AssociationKeys.firstName]?: string;
  [AssociationKeys.lastName]?: string;
  [RegistrationSummaryEmailKeys.registrationType]?: string;
}

export interface RegistrationSummaryEmailTransaction {
  [RegistrationSummaryEmailKeys.transactionDate]?: string;
  [RegistrationSummaryEmailKeys.transactionTotal]?: string;
  [RegistrationSummaryEmailKeys.transactionStatus]?: string;
}

export interface RegistrationSummaryEmailExcursionGroup {
  [RegistrationSummaryEmailKeys.excursionAttendee]?: string;
  [RegistrationSummaryEmailKeys.excursionName]?: string;
  [RegistrationSummaryEmailKeys.excursionPreferences]?: string;
}

export interface RegistrationSummaryEmailExcursion {
  [RegistrationSummaryEmailKeys.excursionsGroupedDate]?: string;
  [RegistrationSummaryEmailKeys.excursionsGroup]?: RegistrationSummaryEmailExcursionGroup[];
}

export interface RegistrationSummaryEmail extends NotifyEmail {
  [RegistrationSummaryEmailKeys.checkInDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.checkOutDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.requestedCheckInDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.requestedCheckOutDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.eventStartDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.eventEndDate]?: LocalDateTimeString;
  [RegistrationSummaryEmailKeys.hotelPhoneNumber]?: string;
  [RegistrationSummaryEmailKeys.flightItineraryEmail]?: string;
  [RegistrationSummaryEmailKeys.hasDifferBookingDatesRequest]?: boolean;
  [RegistrationSummaryEmailKeys.hasAdditionalRoomRequest]?: boolean;
  [RegistrationSummaryEmailKeys.excursions]?: RegistrationSummaryEmailExcursion[];
  [RegistrationSummaryEmailKeys.registrationDetails]?: RegistrationSummaryEmailRegistrationDetail[];
  [RegistrationSummaryEmailKeys.transactions]?: RegistrationSummaryEmailTransaction[];
  [RegistrationSummaryEmailKeys.eventName]?: string;
  [RegistrationSummaryEmailKeys.hotelName]?: string;
  [RegistrationSummaryEmailKeys.hotelState]?: string;
  [RegistrationSummaryEmailKeys.hotelAddress]?: string;
  [RegistrationSummaryEmailKeys.coordinatorPrimaryPhoneNumber]?: string;
  [RegistrationSummaryEmailKeys.coordinatorEmail]?: string;
  [ExcursionConfigurationsEmailKeys.excursions]?: ExcursionConfigurationsEmail[];
  [ExcursionConfigurationsEmailKeys.travelDates]?: string;
  [ExcursionConfigurationsEmailKeys.hotelCheckInDate]?: string;
  [ExcursionConfigurationsEmailKeys.hotelCheckOutDate]?: string;
}
