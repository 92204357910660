<ag-shr-modal-window
  #guestModalComponentRef
  *var="Entity.conferenceAttendeeDetails | hasPermission: activity | async as canWrite"
  [title]="title"
  [width]="800"
  [height]="1000"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  [showSaveButton]="canWrite"
  (onSaveClick)="handleSaveApproveDenyReason($event)"
>
  <ag-shr-select-association
    [isReadonly]="!canWrite"
    [newAssociationTitle]="'Add another'"
    [association]="guestFormData"
    [associationKeyExpr]="getAssociationKey"
    [associations]="agentAssociations$ | async"
    [propertiesToAssign]="propertiesToAssign"
    [onAssociationChanged]="onAssociationChanged"
  ></ag-shr-select-association>

  <dx-form
    *ngIf="guestModalComponentRef?.popupComponent?.visible ?? false"
    #guestFormRef
    [formData]="guestFormData"
    labelMode="floating"
    class="guest-form"
    [screenByWidth]="formSize$ | async | formScreenByWidth"
    [readOnly]="!canWrite"
    (onInitialized)="onFormInitialized($event)"
  >
    <dxi-item itemType="group" caption="Guest Type" [colCountByScreen]="colCountByScreen">
      <dxi-item
        [colSpan]="3"
        [dataField]="GuestKeys.isComplimentary"
        [editorType]="'dxSelectBox'"
        [label]="{ text: 'Guest Type' }"
        [editorOptions]="{
          items: guestTypesLookup,
          displayExpr: LookupKeys.description,
          valueExpr: LookupKeys.value,
          readOnly: !canWrite,
        }"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Preferred Name" [colCountByScreen]="colCountByScreen">
      <dxi-item
        [colSpan]="2"
        [dataField]="AssociationKeys.prefix"
        [editorType]="'dxSelectBox'"
        [label]="{ text: 'Prefix' }"
        [editorOptions]="{
          items: prefixesLookup$ | async,
          displayExpr: LookupKeys.description,
          valueExpr: BaseModelKeys.dbId,
          showClearButton: true,
          allowClearing: true,
          readOnly: !canWrite,
        }"
      >
      </dxi-item>

      <dxi-item [colSpan]="4" [dataField]="AssociationKeys.firstName" [label]="{ text: 'First Name' }"></dxi-item>

      <dxi-item [colSpan]="4" [dataField]="AssociationKeys.lastName" [label]="{ text: 'Last Name' }"></dxi-item>

      <dxi-item
        [colSpan]="2"
        [dataField]="AssociationKeys.suffix"
        [label]="{ text: 'Suffix' }"
        [editorType]="'dxSelectBox'"
        [editorOptions]="{
          items: suffixesLookup$ | async,
          displayExpr: LookupKeys.description,
          valueExpr: BaseModelKeys.dbId,
          showClearButton: true,
          allowClearing: true,
        }"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Guest badge name (i.e. John Smith):" [colCountByScreen]="colCountByScreen">
      <dxi-item [colSpan]="3" [dataField]="GuestKeys.badgeName" [label]="{ text: 'Badge Name' }"></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Gender:" [colCountByScreen]="colCountByScreen">
      <dxi-item
        [colSpan]="3"
        [dataField]="AssociationKeys.gender"
        [label]="{ text: 'Gender' }"
        [editorType]="'dxSelectBox'"
        [editorOptions]="{
          items: gendersLookup$ | async,
          displayExpr: LookupKeys.description,
          valueExpr: BaseModelKeys.dbId,
          showClearButton: true,
          allowClearing: true,
        }"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Date of Birth:" [colCountByScreen]="colCountByScreen">
      <dxi-item
        [colSpan]="3"
        [dataField]="AssociationKeys.dob"
        editorType="dxDateBox"
        [label]="{ text: 'Day of Birth' }"
        [editorOptions]="dateEditorOptions"
      >
        <dxi-validation-rule
          type="async"
          [message]="'Two adults and two children, or three adults and one child'"
          [validationCallback]="hotelRulesValidationCallback"
        >
        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>

    <dxi-item [itemType]="'group'" caption="Relationship to Attendee:" [colCountByScreen]="colCountByScreen">
      <dxi-item [colSpan]="3" [dataField]="AssociationKeys.associationType" [label]="{ showColon: true }">
        <div *dxTemplate>
          <ag-shr-relationship-type-select-box
            [readOnly]="!canWrite"
            [(value)]="guestFormData && guestFormData[AssociationKeys.associationType]"
            [validationGroup]="validationGroup"
          ></ag-shr-relationship-type-select-box>
        </div>
      </dxi-item>
    </dxi-item>

    <dxi-item
      [itemType]="'group'"
      caption="Shirt Size:"
      [colCountByScreen]="colCountByScreen"
      [visible]="
        ConferenceGuestsStepsConfigurationSectionName.shirtSizeSection
          | conferenceGuestSectionVisible: (conferenceGuestExcludedSections$ | async)
      "
    >
      <dxi-item [colSpan]="12">
        <div *dxTemplate>
          <ag-shr-t-shirt-seizes-form
            [isReadonly]="!canWrite"
            [validationGroup]="tShirtSizesValidationGroup"
            [tShirtSizes]="guestFormData?.[AssociationKeys.tShirtSizes]"
            [isTShortSizeFieldVisible]="false"
            [screenLimits]="{ md: 1400 }"
          ></ag-shr-t-shirt-seizes-form>
        </div>
      </dxi-item>

      <dxi-item
        [dataField]="GuestKeys.childOrAdult"
        [colSpan]="12"
        [label]="{ text: 'Is the size selected a child size or an adult size shirt?', showColon: false }"
        [editorType]="'dxRadioGroup'"
        [cssClass]="'flex-column mt-2'"
        [editorOptions]="{
          items: ageGroups,
          displayExpr: 'description',
          valueExpr: 'value',
        }"
      >
      </dxi-item>
    </dxi-item>

    <dxi-item
      [itemType]="'group'"
      caption="Does your guest have a dietary or personal consideration?"
      [colCountByScreen]="colCountByScreen"
      [visible]="
        ConferenceGuestsStepsConfigurationSectionName.dietaryConsiderationsSection
          | conferenceGuestSectionVisible: (conferenceGuestExcludedSections$ | async)
      "
    >
      <dxi-item [colSpan]="12">
        <div *dxTemplate>
          <ag-shr-dietary-considerations-form
            [isReadOnly]="!canWrite"
            [label]="'Does guest have a dietary or personal consideration?'"
            [validationGroup]="dietaryConsiderationValidationGroup"
            [dietaryConsiderations]="guestFormData?.[AssociationKeys.dietaryConsideration]"
          ></ag-shr-dietary-considerations-form>
        </div>
      </dxi-item>
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
