import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { matcher } from '../../utils/phone-number-data.utils';
import * as handlebars from 'handlebars';
import { lastValueFrom, map, take } from 'rxjs';
import {
  AssociationKeys,
  GuestData,
  GuestKeys,
  HotelReservationKeys,
  RegistrantData,
  RegistrantKeys,
  RegistrationSummaryEmailKeys,
  RegistrationSummaryEmailRegistrationDetail,
  RequestOutcomeKeys,
} from '@ag-common-lib/public-api';
import { IsRequestedDifferentHotelCheckInCheckOutDatesPipe } from 'ag-common-svc/shared/pipes/is-requested-different-hotel-check-in-check-out-dates.pipe';
import { stringFromDate } from 'ag-common-svc/lib/utils/string-from-date';

export enum HtmlTemplates {
  notificationsPrompt = 'notifications-prompt',
}

export enum HBSTemplates {
  capCanaRegistrationSummary = 'notify-email-conference-registration-summary-tmp-upd',
}

@Injectable()
export class HtmlTemplateService {
  constructor(
    private http: HttpClient,
    isRequestedDifferentHotelCheckInCheckOutDatesPipe: IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
  ) {
    handlebars.registerHelper('ifEven', (conditional, options) =>
      conditional % 2 != 0 ? options.fn(this) : options.inverse(this),
    );
    handlebars.registerHelper('dateFormat', context => stringFromDate(context));
    handlebars.registerHelper('phoneNumberFormat', option => matcher(option));
    handlebars.registerHelper('ifDifferBookingDatesRequest', function (hotelReservation, options) {
      console.log('ifDifferBookingDatesRequest handlebars helper', hotelReservation, options);
      if (!hotelReservation) {
        return options.inverse(this);
      }

      const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
      const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
      const requestedCheckInDate = hotelReservation?.[HotelReservationKeys.requestedCheckInDate];
      const requestedCheckOutDate = hotelReservation?.[HotelReservationKeys.requestedCheckOutDate];
      const hasDifferBookingDatesRequest =
        isRequestedDifferentHotelCheckInCheckOutDatesPipe.transform(
          checkInDate,
          checkOutDate,
          requestedCheckInDate,
          requestedCheckOutDate,
        ) && !hotelReservation?.[HotelReservationKeys.requestDifferBookingDatesOutcome]?.[RequestOutcomeKeys.state];

      if (hasDifferBookingDatesRequest) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    handlebars.registerHelper('registrationDetails', options => {
      debugger;
      const rows = [];
      const rootData = options?.data?.root;
      const registrantData = rootData?.registrantData;
      const guestsData: GuestData[] = rootData?.guestsData;

      if (!registrantData) {
        return options.inverse(this);
      }

      const registrant = {
        [AssociationKeys.firstName]: registrantData?.[RegistrantKeys.firstName],
        [AssociationKeys.lastName]: registrantData?.[RegistrantKeys.lastName],
        [RegistrationSummaryEmailKeys.registrationType]: 'Qualifier',
      };

      rows.push(registrant);

      guestsData?.forEach(guestData => {
        const isComplimentary = guestData?.[GuestKeys.isComplimentary];
        const guest = {
          [AssociationKeys.firstName]: guestData?.[RegistrantKeys.firstName],
          [AssociationKeys.lastName]: guestData?.[RegistrantKeys.lastName],
          [RegistrationSummaryEmailKeys.registrationType]: `${isComplimentary ? 'Complimentary' : 'Additional'} Guest`,
        };
        rows.push(guest);
      });

      if (!!rows?.length) {
        return options.fn(rows);
      }
      return options.inverse(this);
    });
  }

  getTemplateContent(template: HtmlTemplates | HBSTemplates, context = {}): Promise<string> {
    // const request = this.http.get(`assets/handlebars-templates/${template}.hbs`, { responseType: 'text' });
    const request = this.http.get(`assets/html/${template}.html`, { responseType: 'text' });

    return lastValueFrom(
      request.pipe(
        map(emailSource => {
          const emailTemplate = handlebars.compile(emailSource);

          return emailTemplate(context);
        }),
        take(1),
      ),
    );
  }

  private createRegistrationDetailSummaryEmail(
    registrantData: RegistrantData,
    guestsData: GuestData[],
  ): RegistrationSummaryEmailRegistrationDetail[] {
    const registrant = {
      [AssociationKeys.firstName]: registrantData?.[RegistrantKeys.firstName],
      [AssociationKeys.lastName]: registrantData?.[RegistrantKeys.lastName],
      [RegistrationSummaryEmailKeys.registrationType]: 'Qualifier',
    };
    const guests = guestsData.map(guest => {
      const isComplimentary = guest?.[GuestKeys.isComplimentary];
      return {
        [AssociationKeys.firstName]: guest?.[RegistrantKeys.firstName],
        [AssociationKeys.lastName]: guest?.[RegistrantKeys.lastName],
        [RegistrationSummaryEmailKeys.registrationType]: `${isComplimentary ? 'Complimentary' : 'Additional'} Guest`,
      };
    });

    return [registrant, ...guests];
  }
}
