<dx-form [formData]="flightPreference" [labelLocation]="'top'" labelMode="floating">
  <!-- Group: Preferred Airline -->
  <dxi-item itemType="group" caption="Preferred Airlines" [colCount]="3">
    <div *dxTemplate>
      <dx-data-grid
        [dataSource]="flightPreference.airlinePreferences"
        [showBorders]="true"
        [filterRow]="{ visible: true }"
        [columnAutoWidth]="true"
        (onSaved)="onRowSaved()"
        (onRowValidating)="onRowValidating($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
      >
        <dxo-toolbar>
          <dxi-item name="addRowButton"></dxi-item>
        </dxo-toolbar>

        <dxo-editing mode="row" [useIcons]="true" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
        </dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

        <!-- Columns -->
        <dxi-column
          dataField="airline"
          caption="Preferred Airline"
          editCellTemplate="airlineEditorCellTemplate"
          [sortIndex]="0"
          [sortOrder]="'asc'"
        >
          <dxo-lookup [dataSource]="AIRLINES_LOOKUP" displayExpr="description" valueExpr="value"></dxo-lookup>

          <dxi-validation-rule type="required" message="Airline is required"></dxi-validation-rule>

          <!-- Custom Editor for Editing -->
          <div *dxTemplate="let cellInfo of 'airlineEditorCellTemplate'">
            <dx-select-box
              [value]="cellInfo.value"
              [dataSource]="filteredAirlines$ | async"
              valueExpr="value"
              displayExpr="description"
              [searchEnabled]="true"
              (onValueChanged)="onValueChanged($event, cellInfo); updateFilteredAirlines()"
            >
            </dx-select-box>
          </div>
        </dxi-column>

        <dxi-column
          dataField="otherPreferredAirline"
          caption="Other Airline"
          dataType="string"
          [visible]="true"
          [minWidth]="160"
          [editCellTemplate]="'otherAirlineEditor'"
        >
          <div *dxTemplate="let cellInfo of 'otherAirlineEditor'">
            <dx-text-box
              [(value)]="cellInfo.value"
              (onValueChanged)="cellInfo.setValue($event.value)"
              [disabled]="cellInfo.row.data.airline !== Airlines.Other"
              [readOnly]="cellInfo.row.data.airline !== Airlines.Other"
              placeholder="Select Other airline"
            >
            </dx-text-box>
          </div>
        </dxi-column>

        <dxi-column
          dataField="loyaltyNumber"
          caption="Loyalty Number"
          [validationRules]="[{ type: 'stringLength', max: 20 }]"
        ></dxi-column>

        <dxi-column
          dataField="isFirstChoiceAirline"
          caption="1st Choice"
          [dataType]="'boolean'"
          [width]="110"
          [cellTemplate]="'firstChoiceCheckboxTemplate'"
          [editCellTemplate]="'firstChoiceEditCheckboxTemplate'"
        >
          <div *dxTemplate="let cellInfo of 'firstChoiceCheckboxTemplate'">
            <dx-check-box [value]="cellInfo.value" [disabled]="true" [readOnly]="true"></dx-check-box>
          </div>

          <div *dxTemplate="let cellInfo of 'firstChoiceEditCheckboxTemplate'">
            <dx-check-box
              [value]="cellInfo.value"
              (valueChange)="cellInfo.setValue($event)"
              [disabled]="isFirstChoiceDisabled(cellInfo)"
              [readOnly]="isFirstChoiceDisabled(cellInfo)"
            ></dx-check-box>
          </div>
        </dxi-column>

        <dxi-column
          dataField="isSecondChoiceAirline"
          caption="2nd Choice"
          [dataType]="'boolean'"
          [width]="110"
          [cellTemplate]="'secondChoiceCheckboxTemplate'"
          editCellTemplate="secondChoiceEditCheckboxTemplate"
        >
          <div *dxTemplate="let cellInfo of 'secondChoiceCheckboxTemplate'">
            <dx-check-box [value]="cellInfo.value" [disabled]="true" [readOnly]="true"></dx-check-box>
          </div>

          <div *dxTemplate="let cellInfo of 'secondChoiceEditCheckboxTemplate'">
            <dx-check-box
              [value]="cellInfo.value"
              (valueChange)="cellInfo.setValue($event)"
              [disabled]="isSecondChoiceDisabled(cellInfo)"
              [readOnly]="isSecondChoiceDisabled(cellInfo)"
            ></dx-check-box>
          </div>
        </dxi-column>

        <dxi-column type="buttons">
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete"></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dxi-item>

  <!-- Group: Other -->
  <dxi-item itemType="group" caption="Other" [colCount]="3">
    <dxi-item [dataField]="FlightInfoKeys.knownTravelerNumber" editorType="dxTextBox">
      <dxo-label text="Known Traveler #"></dxo-label>
    </dxi-item>

    <dxi-item
      [dataField]="FlightInfoKeys.seatPreference"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: SEAT_PREFERENCE_LOOKUP,
        displayExpr: LookupKeys.description,
        valueExpr: LookupKeys.value,
      }"
    >
      <dxo-label text="Seat Preference"></dxo-label>
    </dxi-item>

    <dxi-item [dataField]="FlightInfoKeys.preferredDepartureAirport" editorType="dxTextBox">
      <dxo-label text="Preferred Departure Airport"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
