import { Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EntityApplications,
  RoleEntityPermissionMap,
  UserRole,
  UserRoleModelKeys,
  ChangeSourceType,
  Entity
} from 'ag-common-lib/public-api';
import { LoggerService } from 'ag-common-svc/lib/services/logger.service';
import { UserPermissionService } from 'ag-common-svc/lib/services/user-permissions.service';
import { AgentService, AuthService } from 'ag-common-svc/public-api';
import { FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { AuthLogService } from 'ag-common-svc/lib/services/auth-log.service';
import { AppRoutes } from 'src/app/app.model';
import { AgentsRoutes } from 'src/app/ag-crm/agents/agents.model';
import { FIREBASE_APP } from 'ag-common-svc/lib/injections/firebase-app';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';
import { CampaignsRoutes } from 'src/app/ag-campaigns/ag-campaigns.model';
import { NotificationsHubRoutes } from 'src/app/ag-notifications-hub/ag-notifications-hub.model';
import { AdminCrmRoutes } from 'src/app/admin-crm/admin-crm.model';

@Injectable()
export class ARMAuthService extends AuthService {
  readonly armUserRoles$: Observable<UserRole[]>;
  readonly armPermissions$: Observable<RoleEntityPermissionMap>;
  private readonly _armHomePathListByPriority = [
    {
      path: [AppRoutes.Agents],
      entity: [Entity.agentAdminister],
    },
    {
      path: [AppRoutes.Agencies],
      entity: [Entity.agencyAdminister],
    },
    {
      path: [AppRoutes.Carriers],
      entity: [Entity.carrierCrm],
    },
    {
      path: [AppRoutes.Prospects],
      entity: [Entity.prospect],
    },
    {
      path: [AppRoutes.Registrants],
      entity: [Entity.registrants],
    },
    {
      path: [AppRoutes.Events],
      entity: [Entity.conference, Entity.conferenceListAdmin, Entity.conferenceList],
    },
    {
      path: [AppRoutes.ConferenceRegistrations],
      entity: [Entity.conferenceRegistrants],
    },
    {
      path: [AppRoutes.Campaign, CampaignsRoutes.CampaignServices],
      entity: [Entity.campaignServices],
    },
    {
      path: [AppRoutes.Campaign, CampaignsRoutes.CampaignManagementTaskBoard],
      entity: [Entity.campaignTaskBoard],
    },
    {
      path: [AppRoutes.Campaign, CampaignsRoutes.CampaignManagementTaskGrid],
      entity: [Entity.campaignTaskGrid],
    },
    {
      path: [AppRoutes.NotificationsHub, NotificationsHubRoutes.NotificationsScheduler],
      entity: [Entity.notificationsScheduler],
    },
    {
      path: [AppRoutes.NotificationsHub, NotificationsHubRoutes.NotificationsTemplates],
      entity: [Entity.notificationsTemplates],
    },
    {
      path: [AppRoutes.NotificationsHub, NotificationsHubRoutes.NotificationsChannels],
      entity: [Entity.notificationsChannels],
    },
    {
      path: [AppRoutes.NotificationsHub, NotificationsHubRoutes.DynamicLists],
      entity: [Entity.dynamicListGroups],
    },
    {
      path: [AppRoutes.NotificationsHub, NotificationsHubRoutes.DynamicSegments],
      entity: [Entity.dynamicSegmentsGroups],
    },
    {
      path: [AppRoutes.ChristmasCardList],
      entity: [Entity.reportChristmasCardList],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.TaskListManager],
      entity: [Entity.administrationTaskListManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.ImportRulesManager],
      entity: [Entity.administrationImportRulesManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.LookupsManager],
      entity: [Entity.administrationLookupsManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.ErrorsManager],
      entity: [Entity.administrationErrorsManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.LogsManager],
      entity: [Entity.administrationLogsManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.UserRolesManager],
      entity: [Entity.administrationUserManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.ReportsManager],
      entity: [Entity.administrationReportsManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.SalesGoals],
      entity: [Entity.agentSalesGoal],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.RoleManager],
      entity: [Entity.administrationRoleManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.GoogleAuthManagement],
      entity: [Entity.administrationGoogleAuthManager],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.AuthStats],
      entity: [Entity.administrationAuthStats],
    },
    {
      path: [AppRoutes.Administration, AdminCrmRoutes.AgentMergeTool],
      entity: [Entity.administrationMergeTool],
    },
  ];

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,

    private toastrService: ToastrService,

    ngZone: NgZone,
    router: Router,
    route: ActivatedRoute,
    agentService: AgentService,
    loggerService: LoggerService,
    userPermissionService: UserPermissionService,
    userRolesService: UserRolesService,
    authLogService: AuthLogService,
  ) {
    super(
      fireBaseApp,
      ngZone,
      router,
      route,
      toastrService,
      agentService,
      loggerService,
      userPermissionService,
      userRolesService,
      authLogService,
    );
    this.afterLogoutRedirectPath = 'login-form';

    this.auth = getAuth(fireBaseApp);

    this.armUserRoles$ = this.userRoles$.pipe(
      map((userRoles: UserRole[]) => {
        return userRoles.filter(
          role => role[UserRoleModelKeys.isEnable] && role[UserRoleModelKeys.appId] === EntityApplications.crm,
        );
      }),
      shareReplay(1),
    );

    this.armPermissions$ = this.armUserRoles$.pipe(
      map((userRoles: UserRole[]) => {
        // Initialize an empty object to store permissions
        const permissionsMap: RoleEntityPermissionMap = {};

        // Iterate over user roles
        userRoles.forEach(userRole => {
          // Get the activity permissions from userRole
          const activityPermissions = userRole[UserRoleModelKeys.activity] ?? {};

          // Iterate over activity permissions
          Object.keys(activityPermissions).forEach(entityKey => {
            // Check if entity key exists in permissionsMap, if not, initialize it
            if (!permissionsMap[entityKey]) {
              permissionsMap[entityKey] = {};
            }

            // Add or update permission values for each entity
            Object.keys(activityPermissions[entityKey]).forEach(permissionKey => {
              // If permission was already set to true, keep it as true otherwise use the current value
              permissionsMap[entityKey][permissionKey] =
                permissionsMap[entityKey][permissionKey] === true || activityPermissions[entityKey][permissionKey];
            });
          });
        });

        return permissionsMap;
      }),
      shareReplay(1),
    );
    // Set ARM landing page based on permission
    this.homePathCommands$ = this.armPermissions$.pipe(
      map(
        permissionsMap =>
          this._armHomePathListByPriority.find(item => hasPermissionGuard(item.entity, permissionsMap))?.path ?? [
            AppRoutes.Agents,
            AgentsRoutes.AgentsList,
          ],
      ),
      shareReplay(1),
    );
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    await super.signInWithEmailAndPassword(email, password);

    const hasPermissions = await this.checkARMPermissions();

    if (!hasPermissions) {
      const errorCode = await this.logMessage('LOGIN', email, 'Agent have no User Permissions to access ARM.');
      this.toastrService.error(
        `<br/> <p>Permissions to Access ARM has not been granted.</p><p>Please contact Alliance Group for Assistance with this code: ${errorCode}</p>`,
        'Access Denied',
        {
          enableHtml: true,
          disableTimeOut: true,
        },
      );

      return;
    }

    try {
      const actionName = ChangeSourceType.regularArm;
      await this.logUserIntoPortal(actionName);
    } catch (error) {}

    await this.navigateForward();
  }

  public checkARMPermissions = async (): Promise<boolean> => {
    const userRoles = await firstValueFrom(this.userRoles$);
    if (!Array.isArray(userRoles) || !userRoles?.length) {
      return false;
    }

    return userRoles?.some(
      role => role[UserRoleModelKeys.isEnable] && role[UserRoleModelKeys.appId] === EntityApplications.crm,
    );
  };

  protected onAgentEmailNotVerified = async () => {
    this.toastrService.error(
      '<p>To continue You need to validate the provided Email Address. <br/> Use Agent Portal for Verification.</p>',
      'Email Not Verified',
      {
        enableHtml: true,
      },
    );
  };
}
