import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ARMAuthService } from 'src/app/shared/services/util/arm-auth.service';
import { map, Observable } from 'rxjs';
import { Entity, RoleEntityPermissionMap } from '@ag-common-lib/public-api';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';
import { tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app.model';

@Injectable({ providedIn: 'root' })
export class AgentMergeToolGuard implements CanActivate {
  constructor(
    private authService: ARMAuthService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.armPermissions$.pipe(
      map((roleEntityPermissionMap: RoleEntityPermissionMap) => {
        return hasPermissionGuard([Entity.administrationMergeTool], roleEntityPermissionMap);
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          this.router.navigate(['/', AppRoutes.AccessDenied]);
        }
      }),
    );
  }
}
