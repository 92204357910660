import { Pipe, PipeTransform } from '@angular/core';
import { FunctionsNames, MediaSize, Constants } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../../../../ag-common-svc/src/lib/services/cloud-functions.service';
import { ImageCropperPayload } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/image-cropper.model';

@Pipe({
  name: 'wasabiImg',
})
export class WasabiImgPipe implements PipeTransform {
  constructor(private cloudFunctionsService: CloudFunctionsService) {}
  transform(
    path: string,
    options?: {
      mediaSize?: MediaSize;
      optimized?: boolean;
      type?: typeof Constants.EMAIL_IMAGE_MIME_TYPE;
      cropperPayload?: ImageCropperPayload;
    },
    // type: MediaSize = MediaSize.origin,
    // optimized: boolean = false,
    // cropperPayload?: ImageCropperPayload,
  ): string {
    if (!path) {
      return null;
    }
    const optimized = options?.optimized ?? false;
    const mediaSize = options?.mediaSize ?? MediaSize.origin;
    const cropperPayload = options?.cropperPayload;
    const type = options?.type;

    let url = `${this.cloudFunctionsService.getFunctionUrl(FunctionsNames.getMedia)}/${path}/${mediaSize}`;

    const queryParams = new URLSearchParams();
    // fix 500 error for uncropped images and optimized true
    if (!!optimized && !!cropperPayload) {
      queryParams.append('optimized', 'true');
    }

    const relativeX1 = cropperPayload?.relativeCropperPosition?.relativeX1;
    const relativeX2 = cropperPayload?.relativeCropperPosition?.relativeX2;
    const relativeY1 = cropperPayload?.relativeCropperPosition?.relativeY1;
    const relativeY2 = cropperPayload?.relativeCropperPosition?.relativeY2;

    if ([relativeX1, relativeX2, relativeY1, relativeY2].every(Boolean)) {
      queryParams.append('cropperRelativeX1', relativeX1);
      queryParams.append('cropperRelativeX2', relativeX2);
      queryParams.append('cropperRelativeY1', relativeY1);
      queryParams.append('cropperRelativeY2', relativeY2);
    }

    if (type) {
      queryParams.append('type', type);
    }

    const result = [url, queryParams.toString()].filter(Boolean).join('?');

    return result;
  }
}
