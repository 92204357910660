import { NgModule } from '@angular/core';
import { DxValidatorModule } from 'devextreme-angular';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { FlightPreferencesComponent } from './flight-preferences.component';
import { AgEditorToolbarModule } from '../../../../../lib/components';
import { FlightPreferenceFormModule } from '../flight-preference-form/flight-preference-form.module';
import { PreferredAirlinePipe } from '../../../../../shared/pipes/conference-registration/preferred-airline.pipe';

@NgModule({
  declarations: [FlightPreferencesComponent],
  imports: [
    SharedModule,
    ModalWindowModule,
    DxValidatorModule,
    AgEditorToolbarModule,
    FlightPreferenceFormModule,
    PreferredAirlinePipe,
  ],
  exports: [FlightPreferencesComponent],
})
export class FlightPreferencesModule {}
