import { groupBy } from 'lodash';
import { LookupKeys } from '../crm/lookup.model';
import { AGMedia } from 'ag-common-lib/public-api';

export enum Sender {
  events = 'events',
  noReplay = 'noReplay',
}

export const SenderMap = new Map([
  [Sender.events, 'Events'],
  [Sender.noReplay, 'No Replay'],
]);

export const SENDER_CONFIGURATION_MAP = [Sender.events, Sender.noReplay].map(value => {
  const description = SenderMap.get(value);
  return { value, description };
});

export enum EmailTemplates {
  confirmEmailUpdateTmp = 'confirmEmailUpdateTmp',
  confirmRegisterEmailTmp = 'confirmRegisterEmailTmp',
  notifyEmailChangeTmp = 'notifyEmailChangeTmp',
  confirmPasswordUpdateTmp = 'confirmPasswordUpdateTmp',
  notifyEmailConferenceRegistrationSummary = 'notifyEmailConferenceRegistrationSummary',
  notifyEmailSupportTask = 'notifyEmailSupportTask',
  notifyEmailKnowBeforeYouGoToConference = 'notifyEmailKnowBeforeYouGoToConference',
  notifyEmailInviteesActivitiesFirstDay = 'notifyEmailInviteesActivitiesFirstDay',
  notifyEmailInviteesActivitiesSecondDay = 'notifyEmailInviteesActivitiesSecondDay',
  notifyEmailConferenceRegistrationShortSummary = 'notifyEmailConferenceRegistrationShortSummary',
  notifyEmailWinterSummitApprovedToRegister = 'notifyEmailWinterSummitApprovedToRegister',
  notifyEmailWinterSummitDenied = 'notifyEmailWinterSummitDenied',
  notifyEmailWinterSummitRegistrationSummary = 'notifyEmailWinterSummitRegistrationSummary',
  notifyEmailWinterSummitKBYG = 'notifyEmailWinterSummitKBYG',
  notifyEmailWinterSummitKBYGPdfAttache = 'notifyEmailWinterSummitKBYGPdfAttache',
  notifyEmailWinterSummitPending = 'notifyEmailWinterSummitPending',
}

export enum EmailTemplatesModels {
  registrant = 'registrant',
  registrationInquiry = 'registrationInquiry',
}

export class EmailTemplateModel {
  model: EmailTemplatesModels;
  forEvent: string;
  title: string;
}

export const EmailTemplatesDetailsMap = new Map<EmailTemplates, EmailTemplateModel>([
  [
    EmailTemplates.notifyEmailConferenceRegistrationSummary,
    { model: EmailTemplatesModels.registrant, forEvent: 'Cap Cana 2024', title: 'Registration Summary' },
  ],
  [
    EmailTemplates.notifyEmailKnowBeforeYouGoToConference,
    { model: EmailTemplatesModels.registrant, forEvent: 'Cap Cana 2024', title: 'Know Before You Go' },
  ],
  [
    EmailTemplates.notifyEmailInviteesActivitiesFirstDay,
    { model: EmailTemplatesModels.registrant, forEvent: 'Cap Cana 2024', title: 'Activities First Day' },
  ],
  [
    EmailTemplates.notifyEmailInviteesActivitiesSecondDay,
    { model: EmailTemplatesModels.registrant, forEvent: 'Cap Cana 2024', title: 'Activities Second Day' },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitRegistrationSummary,
    { model: EmailTemplatesModels.registrant, forEvent: 'Winter Summit 2025', title: 'Registration Summary' },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitKBYG,
    { model: EmailTemplatesModels.registrant, forEvent: 'Winter Summit 2025', title: 'Know Before You Go' },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitKBYGPdfAttache,
    {
      model: EmailTemplatesModels.registrant,
      forEvent: 'Winter Summit 2025',
      title: 'Know Before You Go with Attachment',
    },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitPending,
    { model: EmailTemplatesModels.registrationInquiry, forEvent: 'Winter Summit 2025', title: 'Application Submitted' },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitApprovedToRegister,
    { model: EmailTemplatesModels.registrationInquiry, forEvent: 'Winter Summit 2025', title: 'Application Approved' },
  ],
  [
    EmailTemplates.notifyEmailWinterSummitDenied,
    { model: EmailTemplatesModels.registrationInquiry, forEvent: 'Winter Summit 2025', title: 'Application Rejected' },
  ],
  [
    EmailTemplates.notifyEmailConferenceRegistrationShortSummary,
    { model: EmailTemplatesModels.registrant, forEvent: 'Generic', title: 'Registration Summary' },
  ],
]);

const RAW_EVENTS_EMAIL_TEMPLATES = Array.from(EmailTemplatesDetailsMap).map(
  ([value, { forEvent: key, title: description }]) => {
    return { value, key, description };
  },
);

export const EVENTS_EMAIL_TEMPLATES = Object.entries(groupBy(RAW_EVENTS_EMAIL_TEMPLATES, 'key')).map(
  ([key, items]) => ({ key, items }),
);

export enum EmailActionTrigger {
  dataConfirm = 'dataConfirm',
  submitApplication = 'submitApplication',
  approveApplication = 'approveApplication',
  rejectApplication = 'rejectApplication',
}

export const EmailActionTriggerMap = new Map([
  [EmailActionTrigger.dataConfirm, 'On Data Confirm'],
  [EmailActionTrigger.submitApplication, 'On Application Submitted'],
  [EmailActionTrigger.approveApplication, 'On Approve Application'],
  [EmailActionTrigger.rejectApplication, 'On Reject Application'],
]);

export const EMAIL_ACTION_TRIGGERS_LOOKUP = [
  EmailActionTrigger.dataConfirm,
  EmailActionTrigger.submitApplication,
  EmailActionTrigger.approveApplication,
  EmailActionTrigger.rejectApplication,
].map(value => {
  const description = EmailActionTriggerMap.get(value);
  return {
    [LookupKeys.value]: value,
    [LookupKeys.description]: description,
  };
});

export enum EmailTemplateConfigurationKeys {
  templateName = 'templateName',
  subject = 'subject',
  trigger = 'trigger',
  sender = 'sender',
  attachments = 'attachments',
}

export class EmailTemplateConfiguration {
  [EmailTemplateConfigurationKeys.templateName]: EmailTemplates;
  [EmailTemplateConfigurationKeys.subject]?: string;
  [EmailTemplateConfigurationKeys.trigger]?: EmailActionTrigger;
  [EmailTemplateConfigurationKeys.sender]: Sender = Sender.events;
  [EmailTemplateConfigurationKeys.attachments]: AGMedia[] = [];
}
