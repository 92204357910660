import { BaseModel } from '../base.model';
import { Address } from '../utils/address.model';
import { EmailAddress } from '../utils/email-address.model';
import { PhoneNumber } from '../utils/phone-number.model';
import { LookupKeys } from '../crm/lookup.model';
import { LookupId } from '../../lists/lookups.enum';
import { WizardStep, WizardStepState } from '../utils/wizard.model';
import { ConferenceRegistrationTask } from './registration-task.model';
import { Headshot } from '../utils/headshot.model';
import { DietaryConsideration } from '../utils/dietary-consideration.model';
import { HotelReservation } from './hotel-reservation.model';
import { TShirtSizes } from '../utils/t-shirt-seizes.model';
import { FlightInformationModel } from './flight-information.model';
import { ConferenceRegistrationStatus } from '../../lists/conference-registrations-status.enum';
import { RegistrationType } from './conference-registration-type.model';
import { SelectedExcursions } from './excursion.model';
import { Association } from '../utils/association.model';
import { RequestOutcome } from '../utils/request-outcome.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { QualifiedAs } from './registrant-qualifying.model';

export enum InviteeStatus {
  initial = 'initial',
  accepted = 'accepted',
  declined = 'declined',
  attended = 'attended',
  cancelled = 'cancelled',
  doNotShowUp = 'doNotShowUp',
}

export const InviteeStatusMap = new Map([
  [InviteeStatus.initial, 'Initial'],
  [InviteeStatus.accepted, 'Accepted'],
  [InviteeStatus.declined, 'Declined'],
  [InviteeStatus.attended, 'Attended'],
  [InviteeStatus.cancelled, 'Cancelled'],
  [InviteeStatus.doNotShowUp, "Didn't Show Up"],
]);

export const INVITEE_STATUS_LOOKUP = [InviteeStatus.initial, InviteeStatus.accepted, InviteeStatus.declined].map(
  status => {
    return { [LookupKeys.value]: status, [LookupKeys.description]: InviteeStatusMap.get(status) };
  },
);
export const INVITEE_OUTCOME_STATUS_LOOKUP = [
  InviteeStatus.attended,
  InviteeStatus.cancelled,
  InviteeStatus.doNotShowUp,
].map(status => {
  return { [LookupKeys.value]: status, [LookupKeys.description]: InviteeStatusMap.get(status) };
});

export enum InviteeAgeGroup {
  child = 'child',
  adult = 'adult',
}

export const INVITEE_AGE_GROUP_LOOKUP = [
  { [LookupKeys.value]: InviteeAgeGroup.child, [LookupKeys.description]: 'Child' },
  { [LookupKeys.value]: InviteeAgeGroup.adult, [LookupKeys.description]: 'Adult' },
];

export const InviteeAgeGroupMap = new Map([
  [InviteeAgeGroup.child, 'Child'],
  [InviteeAgeGroup.adult, 'Adult'],
]);

export enum ReservationStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Failed = 'Failed',
}

export const RESERVATION_STATUS_LOOKUP = [
  { value: ReservationStatus.Pending, description: 'Pending' },
  { value: ReservationStatus.Paid, description: 'Paid' },
  { value: ReservationStatus.Failed, description: 'Failed' },
];

export enum RegistrantKeys {
  conferenceDbId = 'conferenceDbId',
  eventId = 'event_id',
  inviteeEmail = 'invitee_email',
  firstName = 'first_name',
  lastName = 'last_name',
  badgeName = 'badge_name',
  mobilePhone = 'mobile_phone',
  primaryEmailAddress = 'primary_email_address',
  emergencyContact = 'emergency_contact',
  inviteeStatus = 'invitee_status',
  inviteeOutcomeStatus = 'invitee_outcome_status',
  lastEvalDate = 'last_eval_date',
  childOrAdult = 'child_or_adult',
  registrationType = 'registration_type',
  qualifiedAs = 'qualified_as',
  inviteeEmailType = 'invitee_email_type',
  registeredAt = 'registered_at',
  registrationSource = 'registration_source',
  registrationStatus = 'registration_status',
  awardName = 'award_name',
  firstTimeAttendee = 'first_time_attendee',
  groupId = 'group_id',
  ccEmail = 'cc_email',
  rsvp = 'rsvp',
  hotelReservation = 'hotelReservation',
  totalCharge = 'total_charge',
  balanceDue = 'balance_due',
  paymentMethod = 'payment_method',
  relationship = 'relationship',
  prefix = 'prefix',
  suffix = 'suffix',
  passportFirstName = 'passport_first_name',
  passportLastName = 'passport_last_name',
  mgaId = 'mga_id',
  agencyId = 'agency_id',
  upline = 'upline',
  agentId = 'agent_id',
  middleName = 'middle_name',
  gender = 'gender',
  dob = 'dob',
  shoeSize = 'shoe_size',
  favoriteDestination = 'favorite_destination',
  guestType = 'guest_type',
  updatedDate = 'updated_date',
  updatedBy = 'updated_by',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
  plaqueRequired = 'plaqueRequired',
  shippingAddress = 'shippingAddress',
  billingAddress = 'billingAddress',
  isBillingDifferentFromShippingAddress = 'isBillingDifferentFromShippingAddress',
  headshot = 'headshot',
  dietaryConsideration = 'dietaryConsideration',
  numberOfGuests = 'numberOfGuests',
  isGuestsAttended = 'isGuestsAttended',
  additionalGuestRequested = 'additionalGuestRequested',
  additionalGuestRequestOutcome = 'additionalGuestRequestOutcome',
  tShirtSizes = 'tShirtSizes',
  flightInformation = 'flightInformation',
  companyReference = 'companyReference',
  selectedExcursions = 'selectedExcursions',
  title = 'title',

  // temp to show UI:
  isSendPlaque = 'isSendPlaque',
  isSendPlaqueOwner = 'isSendPlaqueOwner',
  isSendPlaqueOwnerNote = 'isSendPlaqueOwnerNote',
}

export enum ConferenceRegistrationStepName {
  rsvpStep = 'rsvpStep',
  registrantPersonalInformationStep = 'registrantPersonalInformationStep',
  registrantPersonalInformationAboutStep = 'registrantPersonalInformationAboutStep',
  registrantPersonalInformationEmailAddressStep = 'registrantPersonalInformationEmailAddressStep',
  registrantPersonalInformationAddressStep = 'registrantPersonalInformationAddressStep',
  registrantPersonalInformationPhoneNumberStep = 'registrantPersonalInformationPhoneNumberStep',
  registrantPersonalInformationShirtSizeStep = 'registrantPersonalInformationShirtSizeStep',
  registrantPersonalInformationAwardNameStep = 'registrantPersonalInformationAwardNameStep',
  registrantPersonalInformationHeadshotStep = 'registrantPersonalInformationHeadshotStep',
  registrantPersonalInformationDietaryConsiderationsStep = 'registrantPersonalInformationDietaryConsiderationsStep',
  registrantPersonalInformationEmergencyContactStep = 'registrantPersonalInformationEmergencyContactStep',
  registrantGuestConfigurationStep = 'registrantGuestConfigurationStep',
  registrationGuestRequestStep = 'registrationGuestRequestStep',
  registrationGuestFlightInformationStep = 'registrationGuestFlightInformationStep',
  registrantHotelReservationStep = 'registrantHotelReservationStep',
  registrantFlightInformationStep = 'registrantFlightInformationStep',
  registrantFlightYourSelectionStep = 'registrantFlightYourSelectionStep',
  registrantExcursionsStep = 'registrantExcursionsStep',
  registrantExcursionYourSelectionStep = 'registrantExcursionYourSelectionStep',
  registrantPaymentStep = 'registrantPaymentStep',
  registrationSummaryStep = 'registrationSummaryStep',
  registrationDeclinedSummaryStep = 'registrationDeclinedSummaryStep',
  registrationConfirmationStep = 'registrationConfirmationStep',
}

export class RegistrantData {
  [RegistrantKeys.eventId]?: string = '';

  [RegistrantKeys.registrationStatus]?: string = '';
  [RegistrantKeys.registrationType]?: RegistrationType = null;
  [RegistrantKeys.registrationSource]?: string = '';

  [RegistrantKeys.qualifiedAs]?: QualifiedAs = null;
  [RegistrantKeys.inviteeEmail]?: string = '';
  [RegistrantKeys.inviteeEmailType]?: string = '';

  [RegistrantKeys.mgaId]?: string = '';
  [RegistrantKeys.title]?: string = '';
  [RegistrantKeys.agencyId]?: string = '';
  [RegistrantKeys.upline]?: string = '';
  [RegistrantKeys.agentId]?: string;

  [RegistrantKeys.shoeSize]?: string;

  [RegistrantKeys.favoriteDestination]?: string = '';

  [RegistrantKeys.groupId]?: string;
  [RegistrantKeys.rsvp]?: string;
  [RegistrantKeys.totalCharge]?: number;
  [RegistrantKeys.balanceDue]?: number;
  [RegistrantKeys.paymentMethod]?: string;

  [RegistrantKeys.createdAt]?: Date;
  [RegistrantKeys.updatedAt]?: Date;

  // RSVP Fields
  [RegistrantKeys.awardName]?: string;
  [RegistrantKeys.inviteeStatus]?: InviteeStatus = InviteeStatus.initial;
  [RegistrantKeys.inviteeOutcomeStatus]?: InviteeStatus;
  [RegistrantKeys.plaqueRequired]?: boolean;

  // Personal Info Fields
  [RegistrantKeys.prefix]?: LookupId<'Prefixes'>;
  [RegistrantKeys.firstName]?: string = '';
  [RegistrantKeys.middleName]?: string = '';
  [RegistrantKeys.lastName]?: string = '';
  [RegistrantKeys.badgeName]?: string;
  [RegistrantKeys.suffix]?: LookupId<'Suffixes'>;
  [RegistrantKeys.passportFirstName]?: string = '';
  [RegistrantKeys.passportLastName]?: string = '';
  [RegistrantKeys.firstTimeAttendee]?: boolean;
  [RegistrantKeys.gender]?: LookupId<'Genders'>;
  [RegistrantKeys.dob]?: LocalDateTimeString = null;
  [RegistrantKeys.headshot]?: Headshot;
  [RegistrantKeys.emergencyContact]?: Association = null;
  [RegistrantKeys.mobilePhone]?: PhoneNumber = null;
  [RegistrantKeys.tShirtSizes]?: TShirtSizes;
  [RegistrantKeys.dietaryConsideration]?: DietaryConsideration = null;

  // Personal Info email
  [RegistrantKeys.primaryEmailAddress]?: Partial<EmailAddress> = null;
  [RegistrantKeys.ccEmail]?: string = null;

  // Personal Info Address
  [RegistrantKeys.isBillingDifferentFromShippingAddress]?: boolean;
  [RegistrantKeys.shippingAddress]?: Partial<Address>;
  [RegistrantKeys.billingAddress]?: Partial<Address>;

  // Guests configuration
  [RegistrantKeys.additionalGuestRequested]?: boolean;
  [RegistrantKeys.additionalGuestRequestOutcome]?: RequestOutcome;
  [RegistrantKeys.isGuestsAttended]?: boolean;
  [RegistrantKeys.numberOfGuests]?: number;

  // Flight configuration
  [RegistrantKeys.flightInformation]?: FlightInformationModel;

  // Hotel configuration
  [RegistrantKeys.hotelReservation]?: HotelReservation;

  // Excursions configuration
  [RegistrantKeys.selectedExcursions]?: SelectedExcursions;

  // temp
  [RegistrantKeys.isSendPlaqueOwner]?: string;
  [RegistrantKeys.isSendPlaqueOwnerNote]?: string;
  [RegistrantKeys.isSendPlaque]?: boolean;
}

export const ConferenceRegistrationRSVPKeys = [
  RegistrantKeys.inviteeStatus,
  RegistrantKeys.plaqueRequired,
  RegistrantKeys.awardName,
  RegistrantKeys.shippingAddress,
] as const;

export const ConferenceRegistrationPersonalInfoKeys = [
  RegistrantKeys.prefix,
  RegistrantKeys.firstName,
  RegistrantKeys.middleName,
  RegistrantKeys.lastName,
  RegistrantKeys.suffix,
  RegistrantKeys.badgeName,
  RegistrantKeys.firstTimeAttendee,
  RegistrantKeys.gender,
  RegistrantKeys.dob,
  RegistrantKeys.registrationType,
] as const;

export const ConferenceRegistrationEmailKeys = [RegistrantKeys.primaryEmailAddress, RegistrantKeys.ccEmail] as const;

export const ConferenceRegistrationAwardNameKeys = [RegistrantKeys.awardName, RegistrantKeys.plaqueRequired] as const;

export const ConferenceRegistrationAddressKeys = [
  RegistrantKeys.isBillingDifferentFromShippingAddress,
  RegistrantKeys.shippingAddress,
  RegistrantKeys.billingAddress,
] as const;

export const ConferenceRegistrationExcursionsKeys = [
  RegistrantKeys.firstName,
  RegistrantKeys.lastName,
  RegistrantKeys.selectedExcursions,
] as const;

export const ConferenceRegistrationGuestsConfigurationKeys = [
  RegistrantKeys.numberOfGuests,
  RegistrantKeys.isGuestsAttended,
  RegistrantKeys.additionalGuestRequested,
] as const;

export type ConferenceRegistrationRSVP = Pick<RegistrantData, (typeof ConferenceRegistrationRSVPKeys)[number]>;

export type ConferenceRegistrationPersonalInfo = Pick<
  RegistrantData,
  (typeof ConferenceRegistrationPersonalInfoKeys)[number]
>;

export type ConferenceRegistrationAwardName = Pick<
    RegistrantData,
    (typeof ConferenceRegistrationAwardNameKeys)[number]
>;

export type ConferenceRegistrationEmail = Pick<RegistrantData, (typeof ConferenceRegistrationEmailKeys)[number]>;

export type ConferenceRegistrationAddress = Pick<RegistrantData, (typeof ConferenceRegistrationAddressKeys)[number]>;

export type ConferenceRegistrationExcursion = Pick<
  RegistrantData,
  (typeof ConferenceRegistrationExcursionsKeys)[number]
>;

export type ConferenceRegistrationGuestsConfiguration = Pick<
  RegistrantData,
  (typeof ConferenceRegistrationGuestsConfigurationKeys)[number]
>;

export type RegistrantGuestConfigurationSteps =
  `${ConferenceRegistrationStepName.registrantGuestConfigurationStep}_${string}`;

export type RegistrantGuestFlightConfigurationSteps =
  `${ConferenceRegistrationStepName.registrantFlightInformationStep}_${string}`;

export type RegistrantExcursionsSteps = `${ConferenceRegistrationStepName.registrantExcursionsStep}_${string}`;

export type ConferenceRegistrationStep = WizardStep<
  | ConferenceRegistrationStepName
  | RegistrantGuestConfigurationSteps
  | RegistrantGuestFlightConfigurationSteps
  | RegistrantExcursionsSteps,
  { registrantGuestDbId?: string }
>;

export const conferenceRegistrationStepsMap = new Map<
  ConferenceRegistrationStepName,
  Pick<ConferenceRegistrationStep, 'title' | 'steps' | 'hasView'>
>([
  [ConferenceRegistrationStepName.rsvpStep, { title: 'RSVP' }],
  [
    ConferenceRegistrationStepName.registrantPersonalInformationStep,

    {
      title: 'Personal Information',
      hasView: false,
      steps: [
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationAboutStep,
          title: 'About You',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep,
          title: 'Email address',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationAddressStep,
          title: 'Address',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep,
          title: 'Phone number',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep,
          title: 'Shirt Size',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationAwardNameStep,
          title: 'Award Name',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationHeadshotStep,
          title: 'Headshot',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep,
          title: 'Dietary Considerations',
        },
        {
          name: ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep,
          title: 'Emergency Contact',
        },
      ],
    },
  ],
  [ConferenceRegistrationStepName.registrantGuestConfigurationStep, { title: 'Guest Registration' }],
  [ConferenceRegistrationStepName.registrantHotelReservationStep, { title: 'Hotel Reservation' }],
  [ConferenceRegistrationStepName.registrantFlightInformationStep, { title: 'Flight Information', hasView: false }],
  [ConferenceRegistrationStepName.registrantExcursionsStep, { title: 'Excursions' }],
  [ConferenceRegistrationStepName.registrationConfirmationStep, { title: 'Confirmation' }],
  [ConferenceRegistrationStepName.registrantPaymentStep, { title: 'Payment' }],
  [ConferenceRegistrationStepName.registrationSummaryStep, { title: 'Registration Summary' }],
]);

export const conferenceRegistrationSectionNameMap = new Map<
  ConferenceRegistrationStepName,
  Pick<ConferenceRegistrationStep, 'title'>
>([
  [ConferenceRegistrationStepName.rsvpStep, { title: 'RSVP' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationAboutStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationAddressStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationAwardNameStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantPersonalInformationHeadshotStep, { title: 'Personal Information' }],
  [
    ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep,
    { title: 'Personal Information' },
  ],
  [ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep, { title: 'Personal Information' }],
  [ConferenceRegistrationStepName.registrantGuestConfigurationStep, { title: 'Guest Registration' }],
  [ConferenceRegistrationStepName.registrantHotelReservationStep, { title: 'Hotel Reservation' }],
  [ConferenceRegistrationStepName.registrantFlightInformationStep, { title: 'Flight Information' }],
  [ConferenceRegistrationStepName.registrantFlightYourSelectionStep, { title: 'Your Flight Information' }],
  [ConferenceRegistrationStepName.registrantExcursionsStep, { title: 'Excursions' }],
  [ConferenceRegistrationStepName.registrationConfirmationStep, { title: 'Confirmation' }],
  [ConferenceRegistrationStepName.registrantPaymentStep, { title: 'Payment' }],
  [ConferenceRegistrationStepName.registrationSummaryStep, { title: 'Thank you for Registering!' }],
]);

export const conferenceRegistrationGuestSectionNameMap = new Map<
  ConferenceRegistrationStepName,
  Pick<ConferenceRegistrationStep, 'title'>
>([
  [ConferenceRegistrationStepName.registrantGuestConfigurationStep, { title: 'Registration' }],
  [ConferenceRegistrationStepName.registrantFlightInformationStep, { title: 'Flight Information' }],
  [ConferenceRegistrationStepName.registrantFlightYourSelectionStep, { title: 'Your Flight' }],
  [ConferenceRegistrationStepName.registrantExcursionsStep, { title: 'Excursions' }],
  [ConferenceRegistrationStepName.registrantExcursionYourSelectionStep, { title: 'Your Selection' }],
]);

export interface ConferenceRegistrationChange<T> {
  currentStepData?: Partial<T>;
  incomingStepData?: Partial<T>;
  isStepDone?: boolean;
}

export interface ConferenceRegistrationMetaData {
  selectedShippingAddressesKey?: string;
  selectedBillingAddressesKey?: string;
  primaryEmailSelectedKey?: string;
  secondaryEmailSelectedKey?: string;
  selectedPhoneNumberKey?: string;
  selectedPEmergencyContactKey?: string;
}

export enum RegistrantModelKeys {
  conferenceDbId = 'conferenceDbId',
  data = 'data',
  metaData = 'metaData',
  task = 'task',
  conferenceRegistrationStatus = 'conferenceRegistrationStatus',
  registrationTicketStatus = 'registrationTicketStatus',
  wizardState = 'wizardState',
  statistic = 'statistic',
  guestsIds = 'guestsIds',
}

export enum RegistrantWizardStateKeys {
  isSubmitted = 'isSubmitted',
}

export interface RegistrantWizardState {
  [RegistrantWizardStateKeys.isSubmitted]?: boolean;
  [ConferenceRegistrationStepName.rsvpStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantPersonalInformationAboutStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantPersonalInformationAddressStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantPaymentStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrationSummaryStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrationConfirmationStep]?: WizardStepState;
  [ConferenceRegistrationStepName.registrantExcursionsStep]?: WizardStepState;
}

export interface ConferenceRegistrationStatistic {
  supportItemsNotArchived: number;
}

export class Registrant extends BaseModel {
  [RegistrantModelKeys.conferenceDbId]?: string;
  [RegistrantModelKeys.guestsIds]?: string[];
  [RegistrantModelKeys.wizardState]?: RegistrantWizardState;
  [RegistrantModelKeys.data]?: RegistrantData;
  [RegistrantModelKeys.task]?: ConferenceRegistrationTask;
  [RegistrantModelKeys.metaData]?: ConferenceRegistrationMetaData;
  [RegistrantModelKeys.conferenceRegistrationStatus]?: ConferenceRegistrationStatus =
    ConferenceRegistrationStatus.invited;
  [RegistrantModelKeys.statistic]?: ConferenceRegistrationStatistic;
}

export class Participants {
  invited: Registrant[];
  denied: Registrant[];
}
