import { Injectable } from '@angular/core';
import { EmailTemplates, EmailToSendConfig, NotificationOptInGroup, NotifyEmailKeys } from 'ag-common-lib/lib';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  BaseCampaignsEmail,
  BaseCampaignsEmailKeys,
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplatesDetailsMap,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrantWizardStateKeys,
} from 'ag-common-lib/public-api';
import {
  ConferenceRegistrationCampaignEmail,
  ConferenceRegistrationEmailCampaignService,
} from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';
import { RegistrationSummaryEmailService } from 'ag-common-svc/lib/services/registration-summary-email.service';
import { confirm } from 'devextreme/ui/dialog';
import { Options } from 'nodemailer/lib/mailer';

export class SendRegistrantsEmailPayload {
  registrants?: Registrant[];
  optInGroup?: NotificationOptInGroup;
  isTestEmail?: boolean;
  emailRecipientTestMode?: string[];
}

@Injectable()
export class AttendeesEmailsService {
  constructor(
    private conferenceRegistrationEmailCampaignService: ConferenceRegistrationEmailCampaignService,
    private registrationSummaryEmailService: RegistrationSummaryEmailService,
  ) {}

  sendEmails = async (emailTemplate: EmailTemplateConfiguration, payload: SendRegistrantsEmailPayload) => {
    const templateName = emailTemplate?.[EmailTemplateConfigurationKeys.templateName];
    const templateConfiguration = EmailTemplatesDetailsMap.get(templateName);
    const description = templateConfiguration?.title;
    const registrants = payload?.registrants;
    const registrantsNumber = registrants.length;
    const isNotSubmitted = registrants.some(
      registrant => !registrant?.[RegistrantModelKeys.wizardState]?.[RegistrantWizardStateKeys.isSubmitted],
    );

    let confirmationMessage = '';
    if (isNotSubmitted) {
      confirmationMessage +=
        registrantsNumber > 1
          ? '<div>Not all registrants completed they registration.</div>'
          : '<div>Registration is not completed.</div>';
    }

    const inviteeText =
      registrantsNumber > 1
        ? `(${registrantsNumber}) Invitees`
        : registrants?.[0]?.[RegistrantModelKeys.data]?.[RegistrantKeys.inviteeEmail];

    confirmationMessage += `<div>Are you sure you want to Send "${description}" Email to ${inviteeText}?</div>`;

    const isConfirmed = payload?.isTestEmail || (await confirm(confirmationMessage, 'Confirm'));

    if (!isConfirmed) {
      return;
    }

    debugger;
    switch (templateName) {
      case EmailTemplates.notifyEmailConferenceRegistrationShortSummary:
      case EmailTemplates.notifyEmailConferenceRegistrationSummary:
      case EmailTemplates.notifyEmailWinterSummitRegistrationSummary:
        await this.sendRegistrationSummaryMassEmail(emailTemplate, payload);
        return;
      case EmailTemplates.notifyEmailKnowBeforeYouGoToConference:
      case EmailTemplates.notifyEmailWinterSummitKBYG:
      case EmailTemplates.notifyEmailWinterSummitKBYGPdfAttache:
        await this.sendBeforeYouGoMassEmail(emailTemplate, payload);
        return;

      case EmailTemplates.notifyEmailInviteesActivitiesFirstDay:
      case EmailTemplates.notifyEmailInviteesActivitiesSecondDay:
        await this.sendInviteesActivitiesByDayMassEmail(emailTemplate, payload);
        return;
    }
  };

  private sendInviteesActivitiesByDayMassEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    payload: SendRegistrantsEmailPayload,
  ): Promise<void> => {
    const promises: Promise<any>[] = [];
    const templateName = emailTemplate?.[EmailTemplateConfigurationKeys.templateName];

    payload?.registrants.forEach(registrant => {
      const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
      const registrantDbId = registrant?.[BaseModelKeys.dbId];

      const promise: Promise<ConferenceRegistrationCampaignEmail | void> = this.registrationSummaryEmailService
        .generateActivitiesEmail(emailTemplate, {
          conferenceDbId,
          registrantDbId,
          isTestEmail: payload?.isTestEmail,
          emailRecipientTestMode: payload?.emailRecipientTestMode,
        })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const mailOptions = this.getMailOptions(context);

          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: EmailTemplates.notifyEmailConferenceRegistrationSummary,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(
            conferenceDbId,
            registrantDbId,
            data as any,
          ),
        );

      promises.push(promise);
    });

    await Promise.all(promises);
  };

  private sendRegistrationSummaryMassEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    payload: SendRegistrantsEmailPayload,
  ): Promise<void> => {
    const promises: Promise<EmailToSendConfig>[] = [];
    const templateName = emailTemplate?.[EmailTemplateConfigurationKeys.templateName];

    payload?.registrants.forEach(registrant => {
      const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
      const registrantDbId = registrant?.[BaseModelKeys.dbId];

      const promise: Promise<any> = this.registrationSummaryEmailService
        .generateRegistrationSummaryEmail(emailTemplate, {
          conferenceDbId,
          registrantDbId,
          isTestEmail: payload?.isTestEmail,
          emailRecipientTestMode: payload?.emailRecipientTestMode,
        })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const mailOptions = this.getMailOptions(context);

          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: templateName,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(conferenceDbId, registrantDbId, data),
        );
      promises.push(promise);
    });

    await Promise.all(promises);
  };

  private sendBeforeYouGoMassEmail = async (
    emailTemplate: EmailTemplateConfiguration,
    payload: SendRegistrantsEmailPayload,
  ): Promise<void> => {
    const promises: Promise<EmailToSendConfig>[] = [];
    const templateName = emailTemplate?.[EmailTemplateConfigurationKeys.templateName];

    payload?.registrants.forEach(registrant => {
      const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
      const registrantDbId = registrant?.[BaseModelKeys.dbId];

      const promise: Promise<any> = this.registrationSummaryEmailService
        .generateRegistrationBeforeYouGoEmail(emailTemplate, {
          conferenceDbId,
          registrantDbId,
          isTestEmail: payload?.isTestEmail,
          emailRecipientTestMode: payload?.emailRecipientTestMode,
        })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const mailOptions = this.getMailOptions(context);

          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: templateName,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(
            conferenceDbId,
            registrantDbId,
            data as any,
          ),
        );

      promises.push(promise);
    });

    await Promise.all(promises);
  };

  private getMailOptions(context): Options {
    const mailOptions: Options = {
      to: context?.[NotifyEmailKeys.email],
      subject: context?.[NotifyEmailKeys.subjectTitle],
    };

    if (!!context?.[NotifyEmailKeys.replyTo]) {
      Object.assign(mailOptions, { replyTo: context?.[NotifyEmailKeys.replyTo] });
    }
    if (context?.[NotifyEmailKeys.ccEmail]?.length) {
      Object.assign(mailOptions, { cc: context?.[NotifyEmailKeys.ccEmail] });
    }
    if (context?.[NotifyEmailKeys.bccEmail]?.length) {
      Object.assign(mailOptions, { bcc: context?.[NotifyEmailKeys.bccEmail] });
    }
    return mailOptions;
  }
}
